@font-face {
  font-family: 'Anti Icons Brands';
  src:  url('fonts/icomoon.eot?876qiu');
  src:  url('fonts/icomoon.eot?876qiu#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?876qiu') format('truetype'),
    url('fonts/icomoon.woff?876qiu') format('woff'),
    url('fonts/icomoon.svg?876qiu#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="aib"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Anti Icons Brands' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ai-profile-compass:before {
  content: "\e900";
}
.ai-shoe:before {
  content: "\e901";
}
.ai-handbag:before {
  content: "\e902";
}
.ai-facebook-f:before {
  content: "\ea93";
}
.ai-facebook:before {
  content: "\ea94";
}
.ai-instagram:before {
  content: "\ea95";
}
.ai-whatsapp:before {
  content: "\ea96";
}
.ai-twitter:before {
  content: "\ea99";
}
.ai-youtube:before {
  content: "\eaa0";
}
.ai-youtube2:before {
  content: "\eaa1";
}
.ai-appleinc:before {
  content: "\eac1";
}
.ai-android:before {
  content: "\eac3";
}
