// ========================================================================================================================================
// Image - Custom
// ========================================================================================================================================

.image {
  background: transparent; // default: $gray-50
}

.img-skeleton {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  height: 100% !important;
  background-color: $dark !important;
  background-image: linear-gradient(90deg, $dark, $gray-700, $dark) !important;
}
