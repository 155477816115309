.bd-shop-all {
  @include media-breakpoint-up(md) {
    overflow-x: unset !important;
  }
}

.sc-shop-all {
  position: relative;
  width: 100%;
  .title-shop {
    font-size: 2rem;
    font-weight: normal;
    .shop-list {
      font-size: 16px;
      font-family: $font-family-sans-serif;
    }
    small {
      font-size: 65%;
    }
  }
  .empty-sos {
    background: $orange;
    color: $white;
    padding: 1rem;
    .row {
      align-items: center;
      h5,
      p {
        margin-bottom: 0;
      }
      .btn {
        border: 2px solid $black;
      }
    }
  }
  .brand {
    display: flex;
    align-items: center;
    img {
      width: 50px;
      object-fit: contain;
      margin-right: 0.5rem;
    }
    p {
      margin-bottom: 0;
    }
  }
  .product-card-wrapper {
    position: relative;
    .holders-only-logo {
      position: absolute;
      right: 1rem;
      bottom: 23%;
      width: 25%;
      @include media-breakpoint-down(sm) {
        bottom: 35%;
        right: 0.5rem;
      }
      @include media-breakpoint-down(md) {
        bottom: 25%;
      }
      @include media-breakpoint-down(lg) {
        bottom: 30%;
      }
    }
    .product-card {
      .product-title {
        font-size: 16px;
        font-weight: $font-weight-bold;
        font-family: $headings-font-family;
        @include media-breakpoint-up($navbar-expand-up) {
          font-size: 18px;
        }
      }
      .product-price {
        span {
          color: $primary;
          font-size: 1rem;
          font-family: $headings-font-family;
          font-weight: $font-weight-normal;
        }
        &.is-on-sale {
          span {
            text-decoration: line-through;
            margin-right: 0.5rem;
            color: $secondary;
            @include media-breakpoint-down(sm) {
              display: block;
            }
            &.sale-price {
              color: $danger;
              text-decoration: none;
              font-size: 0.9rem;
              font-weight: $font-weight-bold;
            }
          }
        }
      }
    }
  }
  .select-fixed {
    z-index: 1090;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 55px;
    background-color: $white;
    .col-select {
      border: 1px solid $gray-100;
      display: flex;
      align-items: center;
    }
  }
  .not-found {
    display: flex;
    align-items: center;
    background-color: $gray-70;
    height: auto;
  }
  .shop-infinite {
    overflow: unset !important;
  }
  .dwp-collection-banner {
    margin-top: 0;
    .container {
      padding-left: 2rem;
      padding-right: 2rem;
      align-items: flex-end;
      padding-bottom: 1rem;
    }
    .cover-title {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
    .btn-group {
      width: fit-content;
    }
    @include media-breakpoint-down(sm) {
      margin-top: 1rem;
      .container {
        padding-top: 1rem;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        padding-bottom: 0.5rem;
      }
      .cover-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-top: 1rem;
        padding-bottom: 1rem;
        .btn-group {
          width: 100%;
        }
      }
    }
  }
  .img-dwp-collection {
    width: 100%;
    cursor: pointer;
  }
}

.select-product {
  margin-bottom: 1rem;
  .select__control {
    height: calc(1em + 1.875rem + 2px) !important;
  }
}

.sort-modal {
  .sort-list {
    list-style: none;
    padding-left: 0;
    .sort-item {
      position: relative;
      border-bottom: 1px solid $gray-200;
      &::after {
        position: absolute;
        right: 1rem;
        top: 30%;
        font-family: "Anti Icons Regular";
        content: varIcon($ai-angle-right);
      }
    }
  }
}

.shop-blank {
  // margin-top: 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @include media-breakpoint-down(sm) {
    min-height: 400px;
    padding-top: 2.5rem;
  }
  .blank-wrapper {
    display: flex;
    align-items: center;
    .skull-image {
      height: auto;
      width: 2.5rem;
    }
    .not-found {
      background-color: transparent;
      span {
        font-size: 1rem;
      }
    }
  }
}

.col-filter {
  position: sticky;
  top: calc(#{$navbar-main-height-desktop} + 1rem);
  .sort-filter-desktop {
    .wrapper {
      height: calc(100vh - #{$navbar-height-desktop});
      overflow: auto;
      padding-bottom: 10rem;
      &::-webkit-scrollbar {
        height: 0;
        width: 0;
        display: none;
      }
      &::-webkit-scrollbar-thumb {
        display: none;
      }
      .sc-filter-categories {
        label {
          @include font-size($font-size-base * 0.875);
          color: $gray-400;
        }
        .list-category {
          list-style: none;
          padding-left: 0;
          li {
            cursor: pointer;
            margin-bottom: 0.5rem;
            &.selected {
              font-weight: $font-weight-bold;
            }
          }
          .list-sub-category {
            list-style: none;
            padding-left: 1rem;
            margin-top: 0.5rem;
            li {
              cursor: pointer;
              margin-bottom: 0.5rem;
              font-weight: $font-weight-normal;
              &.selected {
                font-weight: $font-weight-bold;
              }
            }
          }
        }
      }
    }
  }
}
