// ========================================================================================================================================
// Buttons - Custom
// ========================================================================================================================================

// ==========================================================================
// Hover Color
// ==========================================================================

// Set hover darken color (in %)
$btn-hover-bg-darken-custom: $btn-hover-bg-darken; // EDIT THIS AS NEEDED (Default: 5%)

.btn {
  @each $color, $value in $theme-colors {
    &.btn-outline-#{$color} {
      @include hover-focus {
        background-color: darken($value, $btn-hover-bg-darken-custom);
        border-color: darken($value, $btn-hover-bg-darken-custom);
      }
    }

    &.btn-#{$color} {
      @include hover-focus {
        background-color: darken($value, $btn-hover-bg-darken-custom);
        border-color: darken($value, $btn-hover-border-darken);
      }
    }
  }
}

// ==========================================================================
// Hover Effect
// ==========================================================================

// Toggle hover block right effect for filled & outline buttons
// .btn:not(.btn-link) {
//   @include hover-blockRight;
// }

// Toggle hover underline effect for link buttons
.btn-link {
  @include hover-underline;
  &:before {
    bottom: 0.25rem; // underline effect position (default: 0)
  }
}

.btn-light {
  border: 1px solid $dark;
  background-color: $white;
}

.btn-success {
  border-radius: 5px;
  background-color: $green;
}

// ==========================================================================
// General
// ==========================================================================

// .btn-oval {
//   color: $white;
//   border: 1px solid $white;
//   border-radius: 50%;
//   padding-left: 2.5rem;
//   padding-right: 2.5rem;
//   padding-bottom: 0.75rem;
//   padding-top: 0.75rem;
//   font-size: 1.5rem;

//   &.windows {
//     padding-top: unset;
//   }

//   @include media-breakpoint-down($navbar-expand-up) {
//     font-size: 20px;
//   }

//   &:hover {
//     color: $white;
//     border: 1px solid $white;
//     text-shadow: 0px 0px 10px $white;
//     box-shadow: 1px 1px 9px 0px rgba($white, 0.75);
//   }
// }

// .btn-oval-dark {
//   @extend .btn-oval;
//   color: $dark;
//   border: 1px solid $dark;

//   &:hover {
//     color: $dark;
//     border: 1px solid $dark;
//   }
// }

// .btn-link {
//   text-transform: uppercase;
//   text-decoration: underline;

//   &:hover {
//     text-decoration: underline;
//   }

//   @include media-breakpoint-down($navbar-expand-up) {
//     font-size: 12px;
//   }
// }

// .btn-login {
//   border-radius: 0;
//   border: 0;
//   padding-top: 1rem;
//   padding-bottom: 1rem;

//   &:before {
//     transition: all 0.1s ease-out;
//     bottom: 0rem;
//     content: "";
//     display: block;
//     position: absolute;
//     left: 0;
//     width: 100%;
//     height: 1px;
//     background-color: $gray-100;
//     z-index: 2;
//   }

//   @include hover-focus-active {
//     font-weight: 700;
//   }

//   &.active {
//     font-weight: 700;

//     &:before {
//       height: 2px;
//       background-color: $body-color;
//     }
//   }
// }

.btn {
  font-family: $headings-font-family;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  &.btn-outline-black {
    &:disabled,
    &.disabled {
      opacity: 0.5;
      @include hover-focus-active {
        background: transparent;
      }
    }
  }
}

.btn-google {
  img {
    width: 20px;
    position: relative;
    top: -0.1rem;
  }
  @include hover-focus-active {
    background-color: $white;
    color: $white;
    box-shadow: 0 0 0 1.5px $black;
  }
}
