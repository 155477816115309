.bd-product-details {
  @include media-breakpoint-up($navbar-expand-up) {
    overflow-x: unset !important;
  }
}
.sc-product-details-cover {
  padding-top: 1rem;
  .breadcrumb {
    @include media-breakpoint-down(sm) {
      margin-top: -1rem;
      margin-bottom: 0;
    }
  }
  .col-product-image {
    @include media-breakpoint-down(sm) {
      padding-left: 0;
      padding-right: 0;
    }
    .product-image-slider-wrapper {
      position: relative;
      .slider {
        overflow-x: hidden;
        .slick-arrow {
          background-color: transparent;
          &::before {
            color: $black;
            font-size: 1.25rem !important;
          }
          &.slick-disabled {
            display: none !important;
          }
          &.slick-prev {
            left: 0.5rem;
          }
          &.slick-next {
            right: 0.5rem;
          }
        }
      }
      .holders-only-logo {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        width: 20%;
      }
    }
    .product-image-bar-wrapper {
      position: relative;
      .bar-wrapper {
        width: 100%;
        background-color: $gray-100;
        .progress-bar {
          height: 4px;
          background-color: $primary;
        }
      }
    }
  }
  .col-product-detail {
    @include media-breakpoint-down(sm) {
      margin-top: 1rem;
    }
    .preorder-label,
    .venue-only-label {
      width: 75px;
      position: absolute;
      right: 1.5rem;
    }
    .preorder {
      border: 1px solid black;
      padding: 0.15rem 0.5rem;
      width: fit-content;
      margin-bottom: 0.5rem;
      font-weight: $font-weight-bold;
    }
    .title-row {
      margin-bottom: 1.5rem;
      .category {
        font-size: 1.125rem;
        margin-bottom: 0.75rem;
      }
      .title {
        font-size: 2rem;
        margin-bottom: 0.5rem;
      }
      .price {
        font-weight: $font-weight-normal;
        font-size: 1.25rem;
        line-height: 26px;
        margin-bottom: 0;
        &.is-on-sale {
          span {
            text-decoration: line-through;
            margin-right: 0.5rem;
            color: $secondary;
            @include media-breakpoint-down(sm) {
              display: block;
            }
            &.sale-price {
              color: $danger;
              text-decoration: none;
              font-size: 1rem;
              font-weight: $font-weight-bold;
            }
          }
        }
      }
    }
    .variant-title-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-transform: uppercase;
      margin-bottom: 0.5rem;
      .variant-title {
        margin-bottom: 0;
      }
      .view-guide-btn {
        &:before {
          bottom: -0.125rem;
        }
        margin-bottom: 0;
        cursor: pointer;
      }
    }
    .price {
      color: $primary;
    }
    .variant-item-row {
      margin: 0;
      gap: 1rem;
      margin-bottom: 1rem;
      align-items: center;
      .variant-item {
        width: 70px;
        border: 1px solid $secondary;
        padding: 0.5rem;
        cursor: pointer;
        text-align: center;
        transition: $transition-base;
        @include hover-focus-active {
          border: 1px solid $black;
          box-shadow: 0 0 0 1px $black !important;
        }
        &.selected {
          transition: $transition-base;
          border: 1px solid $black;
          box-shadow: 0 0 0 1px $black !important;
        }
        &.disabled {
          color: rgba($black, 0.175);
          border: 1px solid rgba($black, 0.175);
          @include hover-focus-active {
            border: 1px solid rgba($black, 1);
            box-shadow: 0 0 0 1px $black !important;
          }
          &.selected {
            border: 1px solid rgba($black, 1);
            color: rgba($black, 0.175);
          }
        }
        &.color {
          width: 48px;
          height: 48px;
          padding: 0.25rem;
          & > div {
            height: 100%;
            &.bg-white {
              border: 1px solid $gray-150;
              background: $white;
            }
            &.bg-grey {
              background: $gray-300;
            }
          }
        }
      }
    }
    .add-to-bag-btn {
      margin-top: 0.5rem;
    }
    .sold-out-state {
      color: $danger;
      font-size: 1rem;
    }
    .special-events-product {
      padding: 1rem;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      &.telkomsel,
      &.offline-only {
        background: rgba(236, 199, 127, 0.25);
      }
      &.dwp {
        background: url("../../img/dwp/bg-dwp-xv-shop.jpg") center center
          no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.75rem;
      }
      .wrapper {
        display: flex;
        justify-content: space-between;
        .col-img {
          width: 4.5rem;
          display: flex;
          align-items: flex-start;
          img {
            width: 100%;
            object-fit: contain;
          }
        }
        .col-text {
          width: auto;
          margin-left: 1rem;
          .description {
            margin-bottom: 0.25rem;
            color: $dark;
          }
          .tnc-btn {
            margin-bottom: 0;
            font-weight: $font-weight-bold;
            color: $black;
            cursor: pointer;
          }
        }
      }
      &.offline-only {
        .wrapper {
          justify-content: flex-start;
          gap: 1rem;
          .col-img {
            width: 2rem;
            display: flex;
            align-items: flex-start;
          }
          .col-text {
            margin-left: 0;
            .list-venue {
              padding-left: 1rem;
              margin-bottom: 0;
            }
          }
        }
      }
      &.dwp {
        img {
          // width: 2.875rem;
          height: 5.5rem;
        }
        p {
          color: $white;
          margin-bottom: 0.5rem;
        }
        .title {
          font-size: 1.125rem;
          font-weight: 700;
        }
        span {
          display: none;
        }
        i {
          color: $white;
          font-weight: 600;
        }
        @include media-breakpoint-up(md) {
          padding: 1.125rem;
          gap: 1.5rem;
          img {
            height: 6.25rem;
          }
          span {
            color: $white;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            width: fit-content;
            @include hover-underline;
            cursor: pointer;
            &::before {
              bottom: 0;
              background: $white;
            }
            &:hover {
              color: $white;
            }
          }
        }
      }
    }
  }
}

.sc-product-details-info {
  // .title {
  //   font-size: 2rem;
  //   line-height: 41px;
  // }
  .description {
    line-height: 1.6;
  }
  .story-accordion {
    &.accordion-line {
      border-bottom: none !important;
      .accordion-header {
        border-bottom: 1px solid $gray-100 !important;
        padding-top: 0 !important;
      }
      .story-content {
        padding-top: 1rem;
      }
    }
  }
}

.modal-tnc-telkomsel {
  .modal-title {
    img {
      width: 25px;
      margin-right: 0.5rem;
    }
  }
  .tnc-list {
    padding-left: 1rem;
    .tnc-item {
      margin-bottom: 0.5rem;
    }
  }
}

.modal-tnc-dwp {
  .modal-title {
    > div {
      gap: 0.5rem;
    }
    img {
      max-width: 85px;
      height: auto;
      object-fit: contain;
    }
  }
}
