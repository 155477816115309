.sc-search-list {
  .product-card {
    .product-title {
      font-size: 16px;
      font-weight: normal;
      @include media-breakpoint-up($navbar-expand-up) {
        font-size: 18px;
      }
    }
    .product-price {
      color: $primary;
      font-size: 1.25rem;
      &.is-on-sale {
        span {
          text-decoration: line-through;
          margin-right: 0.5rem;
          color: $secondary;
          @include media-breakpoint-down(sm) {
            display: block;
          }
          &.sale-price {
            color: $danger;
            text-decoration: none;
            font-size: 1rem;
            font-weight: $font-weight-bold;
          }
        }
      }
    }
  }
  .sc-search-blank {
    .search-blank {
      padding: 1rem 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $gray-400;
      @include media-breakpoint-down($navbar-expand-down) {
        padding: 1rem;
      }
      .blank-wrapper {
        display: flex;
        align-items: center;
        .skull-image {
          height: auto;
          width: 2.5rem;
        }
        .not-found {
          background-color: transparent;
          p {
            color: $white;
            padding: 0;
            margin: 0;
            font-size: 1rem;
            font-weight: $font-weight-normal;
          }
        }
      }
    }
  }
  .search-best-seller {
    margin-top: 1.5rem;
    .title-wrapper {
      border-top: 1px solid $dark;
      border-bottom: 1px solid $dark;
      margin-bottom: 1rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      text-align: center;
      .title {
        margin-bottom: 0;
      }
    }
  }
}
