.sc-auth {
  position: relative;
  padding-top: $container-padding;
  padding-bottom: 2.5rem;
  min-height: calc(100vh - #{$navbar-height-mobile});
  @include media-breakpoint-up(md) {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
    min-height: calc(100vh - #{$navbar-height-desktop});
  }
  .cover {
    margin-top: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    .cover-bg {
      height: 100vh !important;
    }
  }
  .container {
    @include media-breakpoint-up(md) {
      max-width: 600px;
    }
  }
  .form-wrapper {
    background: $white;
    padding: 1.25rem;
    @include media-breakpoint-up(md) {
      padding: 2.5rem;
    }
    .form-nav {
      position: relative;
      display: flex;
      margin-bottom: 1.25rem;
      img {
        height: 48px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include media-breakpoint-up(md) {
          height: 60px;
        }
      }
      .btn {
        width: 50%;
        text-transform: uppercase;
        font-weight: $font-weight-normal;
        color: $gray-400;
        @include font-size(1.5rem);
        @include hover-focus-active {
          color: $body-color;
        }
        &:before {
          display: none;
        }
        &.active {
          color: $body-color;
          font-weight: $font-weight-bold;
        }
      }
    }
    .form-body {
      color: $body-color;
      .form-check {
        margin-bottom: 1.5rem;
        .form-check-label {
          font-size: 0.875rem;
          position: relative;
          top: 0.1rem;
        }
      }
      .col-terms {
        display: block;
        line-height: 1.3;
        text-align: center;
        margin-bottom: 0.5rem;
        a {
          font-weight: $font-weight-bold;
          &:hover,
          &:focus,
          &:active {
            text-decoration: underline;
          }
        }
      }
    }
  }
  &.login,
  &.register {
    .form-wrapper {
      @include media-breakpoint-up(md) {
        padding-top: 1.25rem;
      }
    }
  }
  &.update {
    .form-wrapper {
      img {
        width: 100px;
        margin-bottom: 1rem;
      }
      hr {
        background: $gray-200;
        margin: 1.5rem 0;
      }
      .btn {
        width: 190px;
      }
    }
  }

  // .sc-auth-wrapper-mobile {
  //   // display: flex;
  //   // align-items: center;
  //   min-height: calc(100vh - #{$navbar-height-mobile});
  //   background: url("../../img/login/cover.jpg") center center no-repeat;
  //   background-size: cover;
  //   padding: 1rem;
  //   @include media-breakpoint-up(md) {
  //     display: none;
  //   }

  //   .form-wrapper {
  //     background: $white;
  //     padding: 1rem;
  //     .form-body {
  //       .col-subscribe {
  //         .form-check {
  //           .form-check-label {
  //             font-size: 0.875rem;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}

.sc-login-verification {
  position: relative;
  .img-wrapper {
    position: absolute;
    top: 5rem;
    left: 0;
    width: 100%;
  }
}

.col-password-hint {
  font-size: 0.875rem;
  ul {
    margin-bottom: 0;
    padding-left: 2rem;
  }
}

.btn-forgot-password {
  font-size: 0.875rem;
  padding: 0;
  margin-top: -0.375rem;
  margin-bottom: 0.5rem;
  font-weight: $font-weight-normal;
  text-transform: none;
  @include hover-underline;
  &:before {
    bottom: 0;
    height: 1px;
  }
}

.sc-login-wrapper {
  .container {
    // position: unset !important;
    // display: unset !important;
    // align-items: unset !important;
    // height: unset;
    position: relative;
    .active {
      background-color: $dark;
      color: white;
      // border: 1px solid $dark;
    }
    .cover-content {
      width: 100vw !important;
      height: 100vh !important;

      .form-wrapper {
        background-color: $white;
        width: 540px;
        position: absolute;
        top: 13%;
        right: 0;
      }
    }
  }

  .form-body {
    color: $body-color;
    padding: 1.25rem;

    @include media-breakpoint-up(md) {
      padding: 1.5rem 2rem;
    }

    .col-subscribe {
      margin-bottom: 1.5rem;

      .form-check {
        .form-check-label {
          font-size: 0.875rem;
        }
      }
    }

    .col-terms {
      display: block;
      line-height: 1.3;
      text-align: center;
      margin-bottom: 0.5rem;

      a {
        font-weight: $font-weight-bold;

        &:hover,
        &:focus,
        &:active {
          text-decoration: underline;
        }
      }
    }
  }
}

.bd-update-password {
  .sc-update-password {
    .title {
      font-size: 2.5rem;
      font-weight: $font-weight-light;
    }
    .description {
      margin-bottom: 2rem;
    }
    .img-skeleton {
      width: 75%;
      object-fit: contain;
    }
    .brand-logo {
      width: 200px !important;
      height: 50px !important;
      img {
        height: 72px;
        object-fit: contain;
      }
    }
    @include media-breakpoint-down(md) {
      .brand-logo {
        width: 100% !important;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
