.sc-dwp-landing-cover,
.sc-dwp-tnc-cover {
  background: url("../../img/dwp24/merch-landing_desktop.jpg") center center
    no-repeat;
  background-size: cover;
  .col-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include media-breakpoint-up(lg) {
      padding-left: 3rem;
    }
    @include media-breakpoint-up(xl) {
      padding-left: 5rem;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 9rem;
    }
    .overlay {
      display: none;
    }
    .title,
    .description {
      color: $white;
    }
    .title {
      font-size: 1.75rem;
      @include media-breakpoint-up(lg) {
        font-size: 2.25rem;
      }
    }
    .special {
      margin-bottom: 1rem;
      color: $white;
      background: darken($orange, 5%);
      padding: 0.3rem 0.5rem;
      text-transform: uppercase;
      font-size: 0.7rem;
      letter-spacing: 1px;
      width: fit-content;
      @include media-breakpoint-up(lg) {
        padding: 0.375rem 0.675rem;
        font-size: 0.8rem;
      }
      @include media-breakpoint-down(sm) {
        font-size: 0.75rem;
      }
    }
    .description {
      font-size: 0.9rem;
      opacity: 0.8;
      margin-bottom: 0.5rem;
      color: rgba($white, 0.8);
      strong {
        font-weight: $font-weight-normal;
        opacity: 1;
        color: $white;
      }
      span {
        color: $white;
      }
      @include media-breakpoint-up(lg) {
        font-size: 1rem;
      }
      @include media-breakpoint-up(xl) {
        max-width: 90%;
      }
    }
    .label {
      font-size: 0.75rem;
      color: rgba($color: $white, $alpha: 0.6);
      margin-bottom: 0;
      @include media-breakpoint-up(md) {
        font-size: 0.825rem;
      }
      @include media-breakpoint-up(lg) {
        font-size: 0.9rem;
      }
    }
    .btn {
      text-transform: uppercase;
      width: fit-content;
      &::before {
        bottom: 0.5rem;
      }
    }
    .dwp-x-monstore-logo {
      height: 50px;
    }
  }
  @include media-breakpoint-down(sm) {
    position: relative;
    .container {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .overlay {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 65%;
      background: linear-gradient(180deg, rgba(0, 0, 10, 0) 0%, #00000a 40.31%);
      z-index: 1;
    }
    .row {
      margin-left: 0;
      margin-right: 0;
    }
    .col-img {
      margin-top: 0rem;
      margin-bottom: 1rem;
      padding-left: 0;
      padding-right: 0;
      .img-mobile {
        width: 100%;
        max-height: 67.5vh;
        object-fit: cover;
        object-position: top center;
      }
    }
    .col-text {
      margin-top: -20.5rem;
      position: relative;
      z-index: 2;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      height: fit-content;
      .title {
        font-size: 1.5rem;
      }
      .description,
      .btn {
        font-size: 0.875rem;
        margin-bottom: 0;
      }
      .label {
        font-size: 0.825rem;
      }
    }
  }
}

.sc-dwp-landing-products {
  background: url("../../img/dwp24/bg-dwp-24-landing-body.png"),
    #000000 center center no-repeat;
  background-size: cover;
  margin-top: -4.5rem;
  @include media-breakpoint-up(md) {
    margin-top: -0.5rem;
    // padding-top: 9.5rem;
  }
  .shop-infinite {
    overflow-x: hidden !important;
  }
  .product-card-wrapper {
    .card-product {
      .card-body {
        .product-title,
        .product-price {
          color: $white;
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    margin-top: -14rem;
    z-index: 3;
    position: relative;
  }
}

.sc-dwp-tnc-cover {
  .col-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
    img {
      max-height: 3rem;
      object-fit: contain;
    }
  }
  @include media-breakpoint-down(sm) {
    .col-text {
      .btn {
        width: 100%;
      }
    }
  }
}

.sc-dwp-tnc-content {
  background: url("../../img/dwp24/bg-dwp-24-landing-body.png") center center
    no-repeat;
  background-size: cover;
  margin-top: -5rem;
  .content {
    color: $white;
    line-height: 150%; /* 1.5rem */
    letter-spacing: 0.015rem;
    li {
      margin-bottom: 1rem;
    }
  }
  @include media-breakpoint-down(sm) {
    margin-top: -15rem;
    z-index: 3;
    position: relative;
  }
}

.modal-tnc-dwp {
  @include media-breakpoint-down(sm) {
    .modal-wrapper {
      height: calc(var(--vh, 1vh) * 100);
    }
  }
  .close {
    z-index: 3;
    i {
      color: $white;
    }
  }
  .modal-body {
    .modal-content {
      background: $black;
      color: $white;
      .bg-cover {
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        @include media-breakpoint-up(md) {
          top: 2rem;
        }
      }
      .modal-content-header {
        margin-bottom: 1.5rem;
        z-index: 2;
        .title {
          color: $golden;
        }
      }
      .modal-content-footer {
        margin-bottom: 1rem;
        background: $gray-900;
        padding: 1.25rem;
        z-index: 2;
      }
      .img-golden-ticket {
        position: relative;
        z-index: 2;
        width: 150px;
        margin: auto;
        @include media-breakpoint-up(md) {
          width: 200px;
        }
      }
      .modal-title {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        border-bottom: 1px solid $white;
        img {
          max-width: 5rem;
          object-fit: contain;
        }
        display: none;
      }
      ol,
      ul {
        padding-left: 1rem;
      }
      li {
        margin-bottom: 0.5rem;
      }
      .gt-prizes {
        text-align: center;
        .text-prize {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          h6 {
            margin-bottom: 0.25rem;
            font-size: 1.1rem;
            text-align: left;
          }
          p {
            font-size: 0.75rem;
            color: rgba($white, 0.8);
          }
        }
        ul {
          list-style: none;
          padding-left: 0;
          display: flex;
          justify-content: center;
          flex-direction: column;
          li {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 0;
            border-bottom: 1px solid rgba($white, 0.25);
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            p {
              margin: 0;
              // font-size: 1.125rem;
            }
            img {
              width: 60px;
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
  }
}

.sc-dwp-golden-ticket {
  background: $black;
  color: $white;
  padding-top: 5rem;
  padding-bottom: 5rem;
  .bg-cover {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
  }
  .modal-content-header {
    position: relative;
    z-index: 4;
    margin-bottom: 1.5rem;
    z-index: 2;
    .title {
      color: $golden;
    }
  }
  .modal-content-footer {
    position: relative;
    z-index: 4;
    margin-bottom: 1rem;
    background: $gray-900;
    padding: 1.25rem;
    z-index: 2;
  }
  .img-golden-ticket {
    position: relative;
    z-index: 2;
    width: 150px;
    margin: auto;
    @include media-breakpoint-up(md) {
      width: 200px;
    }
  }
  ol,
  ul {
    padding-left: 1rem;
  }
  li {
    margin-bottom: 0.5rem;
  }
  .gt-prizes {
    text-align: center;
    position: relative;
    z-index: 3;
    .text-prize {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      h6 {
        margin-bottom: 0.25rem;
        font-size: 1.1rem;
        text-align: left;
      }
      p {
        font-size: 0.75rem;
        color: rgba($white, 0.8);
      }
    }
    ul {
      list-style: none;
      padding-left: 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
      li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 0;
        border-bottom: 1px solid rgba($white, 0.25);
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        p {
          margin: 0;
          // font-size: 1.125rem;
        }
        img {
          width: 60px;
          margin-right: 0.5rem;
        }
      }
    }
  }
  .btn-primary {
    position: relative;
    z-index: 3;
    background: $orange;
    &:hover,
    &:focus,
    &:active {
      background: darken($orange, 0.1) !important;
    }
  }
}
