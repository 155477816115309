// ==========================================================================
// Error Message General
// ==========================================================================

.error-msg {
  position: fixed;
  right: 1.25rem;
  top: 1.5rem;
  background: $red;
  padding: 1rem 1.25rem;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  color: $white;
  z-index: 99999;
  max-width: 27rem;
  @include media-breakpoint-down(sm) {
    width: 100%;
    top: 1.25rem;
    max-width: calc(100% - 2.5rem);
    text-align: center;
  }
  .ai-times {
    cursor: pointer;
    margin-left: 10px;
    position: absolute;
    top: 0rem;
    right: 0.5rem;
    font-size: 1.25rem;
  }
}

.general-toast {
  position: fixed;
  right: 1.25rem;
  top: 1.5rem;
  padding: 1rem 1.25rem;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  color: $white;
  z-index: 99999;
  max-width: 27rem;
  transition: all 0.25s ease-in-out;
  &.error {
    background: $danger;
  }
  &.success {
    background: $success;
  }
  &.warning {
    background: $warning;
  }
  &.info {
    background: $info;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
    top: 1.25rem;
    max-width: calc(100% - 2.5rem);
    text-align: center;
  }
  .ai-times {
    cursor: pointer;
    margin-left: 10px;
    position: absolute;
    top: 0rem;
    right: 0.5rem;
    font-size: 1.25rem;
  }
}
