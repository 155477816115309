// ========================================================================================================================================
// Footer - Custom
// ========================================================================================================================================

footer {
  h5 {
    @extend .h6;
  }
  .footer-header {
    background-color: $gray-100;
    color: $black;
    .footer-header-content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .title {
        margin-bottom: 0;
        margin-right: 1rem;
        text-transform: uppercase;
      }
      .payment-list {
        padding-left: 0;
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
        .payment-item {
          width: 90px;
        }
      }
    }
  }
  .footer-main {
    color: $white;
    background-color: $gray-800;
    .logo-main {
      width: 150px;
      margin-bottom: 1rem;
    }
    .menu-item {
      width: fit-content;
      cursor: pointer;
      margin-bottom: 1.5rem;
      font-size: 20px;
      @include hover-underline();
      &::before {
        background-color: $white;
        bottom: -0.5rem;
      }
      &:hover {
        color: $white;
      }
    }
    .social-link {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      .social-item {
        background-color: $black;
        color: $white;
        width: 32px;
        height: 32px;
        margin-right: 1rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .newsletter {
      margin-left: 0;
      .input-group {
        align-items: center;
        .form-control {
          border-radius: 0;
          background: $gray-100;
          border: 1px solid $gray-800;
        }
        button {
          border-radius: 0;
          background: $white;
          border: 0;
          border-left: 2px solid $gray-800;
          color: $black;
        }
      }
    }
  }
  .footer-bottom {
    background-color: $black;
    .social-link {
      display: flex;
      align-items: center;
      .social-item {
        background-color: $white;
        color: $black;
        width: 32px;
        height: 32px;
        margin-right: 1rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .copyright {
      height: 100%;
      color: $white;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
  .footer-bottom-mobile {
    background-color: $black;
    color: $white;
    .footer-wrapper {
      display: flex;
      justify-content: space-between;
    }
  }
  &.footer-2 {
    .footer-header {
      background: $gray-800;
      .row-newsletter {
        border-top: 1px solid $gray-400;
        background: $white;
        padding: 1rem;
        .newsletter-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .title {
            margin-bottom: 0;
            text-transform: uppercase;
            @include media-breakpoint-down(sm) {
              margin-bottom: 1rem;
            }
          }
          .newsletter {
            .input-group {
              align-items: center;
              .form-control {
                border-radius: 0;
                background: $gray-100;
                border: 1px solid $gray-800;
              }
              button {
                border-radius: 0;
                background: $white;
                border: 2px solid $gray-800;
                color: $black;
              }
            }
          }
        }
      }
      .row-payment {
        background: $gray-800;
        color: $white;
        hr {
          background-color: $gray-300;
          margin: 0;
        }
        .payment-content {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-top: 1.5rem;
          padding-bottom: 1.5rem;
          @include media-breakpoint-down(sm) {
            justify-content: flex-start;
            border-bottom: 0;
          }
          .title {
            margin-bottom: 0;
            margin-right: 1rem;
            text-transform: uppercase;
            @include media-breakpoint-down(sm) {
              display: none;
            }
          }
          .payment-list {
            padding-left: 0;
            list-style: none;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 0;
          }
        }
        .contact-content {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          @include media-breakpoint-up(xl) {
            justify-content: flex-end;
          }
          ul {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            margin-bottom: 1.5rem;
            @include media-breakpoint-up(md) {
              flex-direction: row;
              margin: 0;
            }
            @include media-breakpoint-up(xl) {
              align-items: flex-end;
            }
            li {
              color: rgba($white, 0.8);
              margin: 0.25rem 0;
              @include media-breakpoint-up(md) {
                margin: 0 1rem 2rem 0;
              }
              @include media-breakpoint-up(xl) {
                margin: 0 0 0 1rem;
              }
            }
          }
          a {
            color: $white;
            font-weight: $font-weight-bold;
            margin-left: 0.5rem;
            &:before {
              bottom: -0.225rem;
              background-color: $white;
              height: 1px;
            }
            &:active {
              color: $white;
            }
          }
        }
        // .social-content {
        //   display: flex;
        //   justify-content: flex-end;
        //   align-items: center;
        //   border-bottom: 1px solid $gray-300;
        //   @include media-breakpoint-down(sm) {
        //     justify-content: flex-start;
        //     padding-bottom: 1.5rem;
        //   }
        //   .title {
        //     margin-bottom: 0;
        //     margin-right: 1.5rem;
        //     text-transform: uppercase;
        //     @include media-breakpoint-down(sm) {
        //       display: none;
        //     }
        //   }
        //   .social-list {
        //     padding-left: 0;
        //     list-style: none;
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     margin-bottom: 0;
        //     gap: 1.5rem;
        //     .social-item {
        //       color: $white;
        //       border-radius: 50%;
        //       display: flex;
        //       align-items: center;
        //       justify-content: center;
        //       .aib {
        //         font-size: 2rem;
        //       }
        //     }
        //   }
        // }
      }
    }
    .footer-bottom {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      background: $gray-800;
      color: $white;
      .col-copyright {
        font-family: $font-default-serif;
        font-size: 0.75rem;
        .site {
          color: rgba($white, 0.8);
          text-transform: uppercase;
        }
        a {
          @extend .text-white;
        }
        @include media-breakpoint-down(sm) {
          order: 2;
        }
      }
      .col-menu {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 1.5rem;
        @include media-breakpoint-down(sm) {
          order: 1;
          justify-content: flex-start;
        }
        .menu-item {
          @include hover-underline;
          @extend .text-white;
          font-size: 1rem;
          line-height: 20px;
          padding-bottom: 0.5rem;
          text-transform: uppercase;
          cursor: pointer;
          @include media-breakpoint-down(sm) {
            width: 100%;
          }
        }
      }
    }
  }
}
