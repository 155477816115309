// ========================================================================================================================================
// Cover - Custom
// ========================================================================================================================================

// ==========================================================================
// Animations
// ==========================================================================

.cover {
  margin-top: -$navbar-height-mobile;
  @include media-breakpoint-up($navbar-expand-up) {
    margin-top: -$navbar-height-desktop;
  }
  .cover-bg {
    animation: zoomInSm 1.5s ease(in-out-quart) forwards;
  }
  .cover-label {
    animation: fadeInUp 1s ease-in-out both;
  }
  .cover-title {
    animation: fadeInUp 1s 0.1s ease-in-out both;
  }
  .cover-text {
    animation: fadeInUp 1s 0.2s ease-in-out both;
  }
  .btn-group {
    animation: fadeInUp 1s 0.3s ease-in-out both;
  }
  .scroll-hint {
    animation: fadeInDown 1s 0.4s ease-in-out both;
  }
}

// ==========================================================================
// General
// ==========================================================================

// Add custom styles here
