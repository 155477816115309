// ========================================================================================================================================
// Navbar - Custom
// ========================================================================================================================================

// ==========================================================================
// General
// ==========================================================================

.navbar {
  .navbar-top {
    .col-left {
      margin-left: -0.75rem; // Set depending on hover style & padding
      @include media-breakpoint-up($navbar-expand-up) {
        margin-left: -0.75rem; // Set depending on hover style & padding
      }
    }
    .col-right {
      margin-right: -0.75rem; // Set depending on hover style & padding
      @include media-breakpoint-up($navbar-expand-up) {
        margin-right: -0.75rem; // Set depending on hover style & padding
      }
    }
  }
  .navbar-main {
    .col-left {
      margin-left: -0.75rem; // Set depending on hover style & padding
      @include media-breakpoint-up($navbar-expand-up) {
        margin-left: -0.75rem; // Set depending on hover style & padding
      }
    }
    .col-right {
      margin-right: -0.75rem; // Set depending on hover style & padding
      @include media-breakpoint-up($navbar-expand-up) {
        margin-right: 0; // Set depending on hover style & padding
      }
    }
  }
}

.navbar-main {
  border-bottom: 1px solid $black;
  z-index: $zindex-fixed;
  .col-brand {
    .navbar-brand {
      cursor: pointer;
      margin-right: 0.75rem;
      padding-left: 0;
      &.dwp {
        width: 130px;
        @media screen and (min-width: 400px) {
          width: 140px;
        }
        @media screen and (min-width: 500px) {
          width: 160px;
        }
        @include media-breakpoint-down(sm) {
          margin-right: 0;
        }
      }
      @include media-breakpoint-up($navbar-expand-up) {
        padding-left: 0.75rem;
        &.dwp {
          width: 225px;
        }
      }
    }
  }
  .col-menu {
    height: 100%;
    display: flex;
    align-items: center;
    .navbar-nav {
      display: none;
      height: 100%;
      align-items: center;
      @include media-breakpoint-up($navbar-expand-up) {
        display: flex;
      }
      .nav-item {
        height: 100%;
        display: flex;
        align-items: center;
        .nav-link {
          padding-left: 0;
          padding-right: 0;
          margin-right: 1.5rem;
          font-size: 1rem;
          font-weight: $font-weight-bold;
        }
      }
    }
    .nav-icon {
      display: block;
      @include media-breakpoint-up($navbar-expand-up) {
        display: none;
      }
    }
  }
  .col-actions {
    .navbar-nav {
      flex-direction: row;
      .nav-item {
        &.wallet-address {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        &.curr-lang-options {
          margin-right: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          @include media-breakpoint-down(sm) {
            margin-right: 0;
          }
        }
        &:last-child {
          margin-right: -0.75rem;
        }
        .account-button {
          position: relative;
          .account-list {
            animation: fadeIn 0.2s;
            width: 200px;
            position: absolute;
            padding: 1rem 0;
            top: 3rem;
            right: 0;
            background-color: $white;
            list-style: none;
            // border-radius: 10px;
            border: 3px solid $black;
            &.hide {
              display: none;
            }
            .account-item {
              cursor: pointer;
              padding: 0.375rem 1.25rem;
              text-align: left;
              font-size: 1rem;
              color: $black;
              transition: $transition-base;
              text-transform: none;
              &:hover {
                color: $primary;
              }
            }
          }
        }
      }
      .nav-link {
        padding: 0.5rem 0.75rem;
        &::before {
          height: 0 !important;
          background: rgba($black, 0);
          transform: none;
        }
        &:hover {
          &::before {
            height: 0 !important;
            background: rgba($black, 0);
            transform: none;
          }
        }
      }
    }
  }
}

// ==========================================================================
// Order
// ==========================================================================

.navbar-main {
  // Mobile
  @include media-breakpoint-down($navbar-expand-down) {
    .col-menu {
      order: 1;
    }
    .col-actions {
      order: 3;
    }
  }
  // Desktop
  @include media-breakpoint-up($navbar-expand-up) {
    .col-menu {
      order: 1;
    }
    .col-brand {
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
    }
    .col-actions {
      order: 3;
    }
  }
}

// ========================================================================================================================================
// Mobile
// ========================================================================================================================================

// ==========================================================================
// Mobile - Navbar Main
// ==========================================================================

.navbar-main {
  .col-left,
  .col-right {
    .nav-item {
      width: 3rem;
    }
    .nav-icon {
      // prettier-ignore
      .ais, .aib, .ail, .air, .icon {
        top: 1px;
      }
      i {
        font-size: 1.125rem;
        &.aib {
          font-size: 1.5rem;
        }
        &.ai-shoe {
          font-size: 0.85rem;
        }
      }
    }
  }
}

.nav-toggler {
  padding: 0.85rem 0.75rem;
  .icon-bar {
    transition: $transition-base;
    width: 22px;
    border-radius: 2px;
    height: 2px; // Alt 1
    // height: 1px; // Alt 2
    & + .icon-bar {
      margin-top: 4px; // Alt 1
      // margin-top: 5px; // Alt 2
    }
  }
}

// ==========================================================================
// Mobile - Menu
// ==========================================================================

// Main
.mobile-menu {
  width: 100%;
  position: absolute;
  top: 0;
  .nav-item {
    overflow: hidden;

    .nav-link {
      font-size: 1.5rem;
    }
  }
  .mobile-menu-content {
    padding: 5rem 0;
  }
  .mobile-menu-content-bottom {
    width: 100%;
    border-top: 1px solid $gray-200;
    position: absolute;
    bottom: 0;

    .mobile-menu-login {
      p {
        font-size: $font-size-sm;
      }

      .row {
        margin-bottom: 3rem;
      }
    }
    .mobile-menu-social {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        margin-bottom: 0;
        text-transform: uppercase;
        font-size: $font-size-sm;
      }
    }
  }
  &.mobile-shop-menu {
    transition-delay: 0s;
    z-index: 100;

    .nav-item {
      .nav-link {
        font-weight: $font-weight-normal;
        font-size: 1.5rem;

        &.btn-back {
          font-size: 1rem;
        }
      }
    }
  }

  &.mobile-account-menu {
    z-index: 100;
    transition-delay: 0s;
    .nav-item {
      .nav-link {
        font-weight: $font-weight-normal;
        font-size: 1.5rem;
        &.btn-back {
          font-size: 1rem;
        }
      }
    }
  }
}

// ==========================================================================
// Shopping Bag - Menu
// ==========================================================================

.bag-menu {
  background: $white !important; // override .mobile-menu
  overflow-y: hidden;
  z-index: 2090;
  width: 100%;
  position: absolute;
  top: 0;
  transition-property: right;
  @include media-breakpoint-up(md) {
    width: 35%;
    min-width: 500px;
  }
  &.slide-left {
    right: -100%;
    left: unset;
    transition-duration: $mobile-menu-duration;
    transition-delay: 0s;
    transition-timing-function: $mobile-menu-ease;
    &.show {
      right: 0;
      left: unset;
      transition-duration: $mobile-menu-duration;
      transition-delay: 0s;
      transition-timing-function: $mobile-menu-ease;
    }
  }

  // @for $i from 1 through $mobile-menu-anim-count {
  //   // Initial
  //   .anim-#{$i} {
  //     opacity: 0;
  //     transform: translateY(100%);
  //     transition-property: all;
  //     transition-duration: 0;
  //     transition-timing-function: $mobile-menu-ease;
  //   }
  //   // Hide
  //   &.hide {
  //     .anim-#{$i} {
  //       opacity: 0;
  //       transition-duration: $mobile-menu-duration;
  //       transition-delay: #{($mobile-menu-anim-count / 10) - $i * 0.1}s;
  //     }
  //   }
  //   // Show
  //   &.show {
  //     .anim-#{$i} {
  //       opacity: 1;
  //       transform: translateY(0);
  //       transition-property: all;
  //       transition-duration: $mobile-menu-duration;
  //       transition-delay: #{$mobile-menu-delay + ($i * 0.1)}s;
  //       transition-timing-function: $mobile-menu-ease;
  //     }
  //   }
  // }
  .bag-menu-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    min-height: 345px;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    transition: all 0.5s ease-in-out;
    .heading {
      padding-top: 1.25rem;
      .container {
        > div {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 2px solid $dark;
          padding-bottom: 1rem;
          h3 {
            margin-bottom: 0;
          }
          .btn-close {
            padding: 0;
            i {
              font-size: 1.5rem;
              line-height: 1.2;
            }
          }
        }
      }
    }
    .bag-menu-content-top {
      display: flex;
      flex-direction: column;
      flex: 1 1 0%;
      overflow-y: auto;
      .bag-menu-preorder {
        margin-top: 1rem;
        position: relative;
        background: $gray-50;
        margin-bottom: 1.125rem;
        padding: 1rem;
        display: flex;
        gap: 0.75rem;
        align-items: center;
        p {
          font-size: 0.9rem;
          font-weight: 400;
          color: $gray-800;
          line-height: 19.2px;
        }
        .img-package {
          width: 2rem;
          height: 2rem;
        }
      }
      .tsel-error {
        margin-top: 1rem;
        margin-bottom: 1rem;
        background: #f9e2e7;
        padding: 1rem;
        &.shake {
          animation: headShake 0.5s ease-in-out both infinite;
        }
        p {
          margin-bottom: 0;
        }
      }
      .empty-state {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 85vh;
        height: calc(var(--vh, 1vh) * 85);
      }
      .product-item {
        .col-content {
          .preorder-label {
            width: 75px;
            margin-bottom: 0.5rem;
          }
          .price {
            font-weight: $font-weight-light;
            font-family: $headings-font-family;
          }
          input {
            border-top: 1px solid $dark;
            border-bottom: 1px solid $dark;
          }
        }
      }
    }
    .bag-menu-content-bottom {
      border-top: 1px solid $gray-100;
      background-color: $white;
      padding: 1rem 0;
      .container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        .content-bottom-left {
          p {
            margin-bottom: 0;
            font-size: 1.125rem;
            @include media-breakpoint-up(md) {
              margin-bottom: 0.25rem;
            }
          }
          small {
            color: $gray-400;
            line-height: 1.2;
            display: block;
          }
        }
        .content-bottom-right {
          min-width: 100%;
          p {
            font-weight: $font-weight-bold;
            color: $primary;
            text-align: left;
            margin-bottom: 0;
            font-size: 1.25rem;
          }
          @include media-breakpoint-up($navbar-expand-up) {
            min-width: 150px;
            padding-left: 1rem;
            p {
              text-align: right;
            }
          }
        }
        .btn {
          margin-top: 1.25rem;
          &.disabled {
            cursor: default;
          }
        }
      }
    }
  }
}

// ========================================================================================================================================
// Desktop
// ========================================================================================================================================

// ==========================================================================
// Desktop - Navbar Top
// ==========================================================================

.navbar-top {
  transition: $transition-base;

  &.hide {
    transition: $transition-base;
    margin-top: -56px;

    @include media-breakpoint-up($navbar-expand-up) {
      margin-top: -32px;
    }
  }

  .nav-link {
    padding: 0 0.75rem;

    i {
      font-size: 1rem;
    }
  }
}

// ==========================================================================
// Desktop - Navbar Main
// ==========================================================================

.navbar-main {
  .desktop-menu {
    position: relative;
    align-items: center;

    .nav-item {
      margin-left: 1rem;

      .nav-link {
        i {
          font-size: 1.125rem;
        }
      }
    }

    // &.menu-center {
    //   position: absolute;
    //   top: 0;
    //   left: 50%;
    //   transform: translateX(-50%);
    // }
    // &.menu-right {
    //   .nav-item {
    //     .nav-link {
    //       margin-left: 0.75rem;
    //       margin-right: 0.75rem;
    //     }
    //   }
    // }
  }
}

// ========================================================================================================================================
// Search
// ========================================================================================================================================

.search-menu {
  background: transparent;
}

.form-search {
  .form-control {
    border-color: transparent;
    border-radius: 2rem;
    padding-left: 2.75rem;
    background: $white;
  }

  .form-search-icon {
    font-size: 1.25rem;
    left: 0.75rem;
  }

  .form-search-close {
    right: 0.7rem;
    background: $gray-100;
    border-radius: 2rem;
    font-size: 0.875rem;
    cursor: pointer;

    @include hover-focus-active {
      background: $gray-200;
    }
  }
}

// Effect (Expand)
.search-menu {
  &.expand {
    .form-group {
      @include media-breakpoint-up($navbar-expand-up) {
        right: 120px;
      }

      .form-search {
        width: 80px;
      }
    }

    &.hide {
      .form-search {
        width: 80px;
      }
    }

    &.show {
      .form-search {
        width: 100%;
      }

      @include media-breakpoint-up($navbar-expand-up) {
        .form-search {
          width: 400px;
        }
      }
    }
  }
}

// Custom
.search-input {
  position: relative;
  .ic-left {
    @include ic-left("Anti Icons Light", $ai-search);
  }
  .ic-right {
    @include ic-right("Anti Icons Light", $ai-times);
  }
  .btn-close {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -1.5rem;
    padding: 0;
    height: 3rem;
    width: 3rem;
    margin-right: -0.75rem;
  }
}

.search-menu-mobile {
  z-index: $zindex-fixed;
}

.search-menu-desktop {
  position: absolute;
  width: 100%;
  left: 0;
  transition: $transition-base;
  bottom: -5.5rem;
  &.hide {
    z-index: -1;
    opacity: 0;
    pointer-events: none;
  }
  &.show {
    opacity: 1;
    pointer-events: initial;
  }
  .form-control {
    padding-left: 3rem;
    border-color: $black;
  }
  .ic-left {
    &:before {
      left: 0.5rem;
    }
  }
  .ic-right {
    &:after {
      right: 0;
    }
  }
}

// ==========================================================================
// Color Theme
// ==========================================================================

// prettier-ignore
.navbar {
  &.navbar-light {
    .navbar-brand {
      .logo-light {
        display: none;
      }

      .logo-dark {
        display: block;
      }
    }

    .nav-toggler {
      .icon-bar {
        background-color: $dark;
      }
    }

    .navbar-top {
      background: $gray-100;

      .nav-item {
        .nav-link {
          color: $primary;

          &:hover {
            color: darken($primary, 10%);
          }
        }
      }
    }

    .navbar-main {
      background: $white;
      .nav-item {
        .nav-toggler .icon-bar {
          background: $primary;
          &:hover {
            background: darken($primary, 10%);
          }
        }
        color: $primary;
        .nav-link,
        .nav-icon {
          color: $dark;
          border: none;
          background: rgba($black, 0);
          &:focus-visible {
            outline: none;
          }
          &:hover {
            color: $primary;
          }
        }
        .nav-link {
          @extend .hover-underline;
        }
      }
    }
  }

  &.navbar-dark, &.navbar-black {
    .navbar-brand {
      .logo-dark {
        display: none;
      }
      .logo-light {
        display: block;
      }
    }
    .navbar-top {
      background: $gray-800;
      .nav-item {
        .nav-link {
          color: $white;

          &:hover {
            color: darken($white, 20%);
          }
        }
      }
    }
    .navbar-main {
      background: $dark;
      .nav-item {
        .nav-toggler .icon-bar {
          background: $white;
          &:hover {
            background: $white;
          }
        }
        color: $white;
        .nav-link,
        .nav-icon {
          color: $white;
          border: none;
          background: rgba($color: $black, $alpha: 0);
          &:focus-visible {
            outline: none;
          }

          &:hover {
            color: darken($white, 20%);
          }
        }
        .nav-link {
          @extend .hover-underline;
          &::before {
            background: darken($white, 20%);
          }
        }
      }
    }
  }
  &.navbar-black {
    .navbar-main {
      background: $black;
    }
  }

  &.transparent {
    .navbar-main {
      background: rgba($black, 0);
      border-bottom: none;
      .navbar-nav {
        .nav-item {
          .nav-link {
            color: $white;
          }
          &.curr-lang-options {
            select {
              background: rgba($black, 0);
              border: none;
            }
          }
          .nav-toggler {
            .icon-bar {
              background: $white;
            }
          }
        }
      }
    }
    + main {
      margin-top: 0 !important;
    }
  }
  &.transparent {
    &:not(.is-scrolled) {
      .navbar-brand {
        .logo-dark {
          display: none;
        }
      }
      &:hover {
        .navbar-brand {
          .logo-dark {
            display: block;
          }
        }
      }
    }
  }
  &.transparent:hover,
  &.is-scrolled {
    .navbar-main {
      background: $white;
      .navbar-brand {
        .logo-light {
          display: none;
        }
      }
      .navbar-nav {
        .nav-item {
          .nav-link {
            color: $dark;
          }
          .nav-toggler {
            .icon-bar {
              background: $dark;
            }
          }
        }
      }
    }
  }
}

.mobile-menu {
  background: $dark;

  // background: $white;
  .nav-item {
    .nav-link {
      color: $white;

      // color: $dark;
      &:hover {
        color: $primary;
      }
    }
  }
}

.desktop-megamenu {
  background-color: $white;
  height: fit-content;
  max-height: 80vh;
  position: absolute;
  left: 0;
  right: 0;
  top: $navbar-main-height-desktop;
  width: 100vw;
  overflow: hidden;
  box-shadow: $box-shadow-sm;
  transition: all 0.2s ease-in-out;
  opacity: 1;
  // height: calc(100vh - #{$navbar-height-desktop});
  overflow-y: auto;
  z-index: $zindex-megamenu;
  &.active {
    animation: fadeInDown 0.2s ease-in-out both;
    pointer-events: all;
  }
  &.hide {
    // opacity: 0;
    pointer-events: none;
    animation: fadeOutDown 0.2s ease-in-out both;
    // pointer-events: none;
  }

  .container {
    display: block;
    height: fit-content !important;
    padding: 2.5rem 1.25rem;

    ul li {
      list-style: none;
    }
  }

  @include media-breakpoint-down($navbar-expand-down) {
    display: none;
  }

  .megamenu-nav-wrapper {
    display: flex;
    flex-wrap: wrap;
    // width: calc(100% - 250px);
    width: calc(100%);
    .megamenu-nav {
      padding: 0;
      width: 100%;
      flex: 0 0 20%;
      max-width: 20%;
      // margin-bottom: 2rem;
      .item {
        cursor: pointer;
        font-weight: $font-weight-bold;
        // font-family: $font-family-pitch-sans;
        display: inline-block;
        margin-bottom: 0;
        font-size: 1rem;
        @include hover-underline;
        &:before {
          bottom: -0.25rem;
        }
      }
      .nav-item-list {
        padding: 0;
        margin-top: 0.75rem;
        li {
          margin-bottom: 0rem;
          &.nav-item {
            margin-right: 0;
            a {
              font-weight: $font-weight-normal;
              text-align: left;
              text-transform: none;
              padding: 0.125rem 0;
              &:before {
                bottom: 0;
              }
            }
          }
          &.active {
            .btn {
              &.hover-underline::before {
                transform: scale3d(1, 1, 1);
                transform-origin: 0% 50%;
                transition-timing-function: ease;
              }
            }
          }
          &.special {
            // display: ;
            .logo-special {
              width: 25px;
              margin-right: 0.25rem;
            }
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
  .d-flex {
    gap: 2.25rem;
  }
}
