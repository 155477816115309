.bd-checkout {
  @include media-breakpoint-up($navbar-expand-up) {
    overflow-x: unset !important;
  }
}
.not-login-modal {
  width: 35%;
  max-width: 35% !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: auto !important;
  margin-right: unset !important;
  @include media-breakpoint-down(sm) {
    width: 100%;
    max-width: 100% !important;
    margin: 0 !important;
  }
  .modal-wrapper {
    padding-top: 2.5rem;
    height: 100%;
    animation: slideInRight 0.3s ease-in-out;
    right: 0;
    @include media-breakpoint-down(sm) {
      position: absolute;
      bottom: 0;
    }
    .modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .modal-content {
        h1 {
          font-size: 3rem;
          @include media-breakpoint-up(sm) {
            margin-bottom: 2.5rem !important;
          }
        }
      }
    }
  }
}
.logo {
  padding: 1rem;
  background-color: $white;
  height: $navbar-main-height-desktop;
  position: fixed;
  top: 0;
  text-align: center;
  @include media-breakpoint-down(sm) {
    text-align: left;
  }
}

.sc-checkout-main {
  margin-top: $navbar-main-height-desktop;
  @include media-breakpoint-down(sm) {
    margin-top: $navbar-main-height-mobile;
  }
  .container {
    .checkout-accordion {
      margin-bottom: 1rem !important;
      &.accordion-line {
        border: none;
        border-bottom: none !important;
        .accordion-header {
          border-bottom: 1px solid $gray-100 !important;
          padding: 1rem !important;
          padding-left: 0 !important;
          @include media-breakpoint-up($navbar-expand-up) {
          }
          .accordion-title {
            text-transform: uppercase;
            font-size: 1rem;
          }
        }
        .accordion-content {
          padding-top: 1rem !important;
          padding-bottom: 0 !important;
        }
        .story-content {
          padding-top: 1rem;
        }
      }
      .purchase-summary {
        width: 100%;
        margin-bottom: 1rem;
        .price {
          font-weight: $font-weight-semibold;
          font-size: 1rem;
        }
        .preorder-label {
          width: 75px;
          margin-bottom: 0.5rem;
        }
      }
      .email {
        width: 100%;
        padding: 1rem 0;
        .input-email {
          position: relative;
          .add-email-btn {
            position: absolute;
            right: 0;
            top: 0;
          }
        }
      }
      .delivery-address {
        width: 100%;
        padding-bottom: 1rem;
        &:before {
          bottom: 0.75rem;
        }
        p {
          margin-bottom: 0;
        }
      }
      .delivery-method {
        width: 100% !important;
        padding-bottom: 1rem;
        .delivery-method-preorder {
          margin-top: 1rem;
          position: relative;
          background: $gray-50;
          margin-bottom: 1.125rem;
          padding: 1rem;
          display: flex;
          gap: 0.75rem;
          align-items: center;
          p {
            font-size: 0.9rem;
            font-weight: 400;
            color: $gray-800;
          }
          .img-package {
            width: 2rem;
            height: 2rem;
          }
        }
        .delivery-method-box {
          border: 1px solid $gray-100;
          padding: 1rem 0.25rem;
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
          width: 100%;
          text-align: center;
          cursor: pointer;
          transition: all 0.5s ease-in-out;
          &:hover {
            border: 1px solid $dark;
          }
          .form-check-input {
            cursor: pointer;
          }
          .form-check-label {
            cursor: pointer;
          }
          &.selected {
            border: 1px solid $dark;
            box-shadow: 0px 0px 0px 1px #333;
          }
        }
        .delivery-method-skeleton {
          // position: absolute !important;
          top: 0 !important;
          left: 0 !important;
          right: 0 !important;
          bottom: 0 !important;
          height: 50px !important;
          background-color: $gray-200 !important;
          background-image: linear-gradient(
            90deg,
            $gray-200,
            $gray-100,
            $gray-200
          ) !important;
        }
      }
      .payment-method {
        width: 100% !important;
        padding-bottom: 1rem;
        .payment-method-box {
          border: 1px solid $gray-100;
          padding: 1rem;
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .payment-method-label {
            display: flex;
          }
          .payment-method-radio {
            cursor: pointer;
            padding-left: 0;
            > input {
              right: 0;
            }
            .form-check-input {
              cursor: pointer;
            }
            .form-check-label {
              cursor: pointer;
            }
          }
        }
        .credit-card-form {
          transition: $transition-base;
          background-color: $gray-70;
          &.hide {
            transition: $transition-base;
            opacity: 0;
          }
        }
      }
    }
    .order-summary-box {
      background: $white;
      @include media-breakpoint-up($navbar-expand-up) {
        border: 1px solid $gray-100;
        top: $navbar-main-height-desktop;
      }
      .content {
        padding: 1.25rem;
        border-top: 1px solid $dark;
        @include media-breakpoint-up(md) {
          padding: 1.5rem;
          border: none;
        }
        .title-wrapper {
          border-bottom: 1px solid $gray-300;
        }
        .special-event-product {
          margin-top: 1rem;
          display: flex;
          align-items: flex-start;
          padding: 1rem;
          background: rgba(236, 199, 127, 0.25);
          img {
            width: 3rem;
            margin-right: 0.5rem;
            object-fit: contain;
          }
          p {
            margin-bottom: 0;
            color: $dark;
          }
          &.dwp {
            cursor: pointer;
            flex-direction: column;
            gap: 0.5rem;
            background: url("../../img/dwp/bg-dwp-xv-shop.jpg") center center
              no-repeat;
            background-size: cover;
            .title {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              i {
                color: $white;
                cursor: pointer;
              }
              img {
                width: 115px;
                object-fit: contain;
              }
            }
            p {
              color: $white;
            }
          }
        }
        .promo-box-wrapper {
          margin-top: 1rem;
          margin-bottom: 1.5rem;
          .promo-box {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid $gray-100;
            padding: 1rem;
            &.is-error {
              margin-bottom: 0.25rem;
              border: 1px solid $danger;
            }
          }
          small {
            color: $danger;
          }
        }
        hr {
          border-top: 1px solid $gray-100;
        }
        .sc-warning {
          position: relative;
          left: -1.5rem;
          width: calc(100% + 3rem);
          background: rgba(#ffc700, 0.2);
          margin-bottom: 1.125rem;
          padding: 1rem;
          display: flex;
          gap: 0.75rem;
          ul {
            padding-left: 1rem;
            margin-bottom: 0;
            li {
              font-size: 0.875rem;
            }
          }
        }
      }
    }
  }
}
.sc-success-checkout {
  margin-top: -$navbar-height-mobile;
  background-size: 100%;
  @include media-breakpoint-down(sm) {
    background: linear-gradient(
      360deg,
      #000000 19.41%,
      rgba(0, 0, 0, 0) 112.69%
    );
  }
  @include media-breakpoint-up($navbar-expand-up) {
    margin-top: -$navbar-height-desktop;
  }
  .col-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    h1 {
      color: $white;
      font-size: 4rem;
      @include media-breakpoint-down(sm) {
        font-size: 2.5rem;
        width: 50%;
      }
    }
    .description {
      color: $white;
      font-weight: 400;
      font-size: 1rem;
      @include media-breakpoint-down(sm) {
        font-size: 1rem;
        text-align: center;
      }
    }
    @include media-breakpoint-down(sm) {
      order: 2;
      align-items: center;
    }
  }
}

.footer-checkout {
  width: 100%;
  background-color: $primary;
  position: fixed;
  bottom: 0;
  left: 0;
  color: $white;
  font-size: 12px;
}
