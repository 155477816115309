// ========================================================================================================================================
// Slider - Custom
// ========================================================================================================================================

// Add custom styles here
.slick-slider {
  &.floating-arrow {
    @include media-breakpoint-up(lg) {
      // overflow: hidden;
      width: 100%;
    }
    .slick-arrow {
      background: transparent !important;
      height: 100%;
      width: 200px;

      &.slick-disabled {
        &:hover {
          background: transparent;
        }
      }

      &::before {
        content: "";
      }
      &.slick-next {
        right: 0;
      }
      &.slick-prev {
        left: 0;
      }
    }
  }
}
