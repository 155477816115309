/*
 * Antikode main.scss
 * Version - 3.0.1
 * Bootstrap - 4.5.0
 * 2021 Antikode
 */

// ========================================================================================================================================
// Import
// ========================================================================================================================================

// ==========================================================================
// Bootstrap Base & Custom Variables
// ==========================================================================

// Required functions / mixins used in variables config
@import "~bootstrap/scss/functions"; // DO NOT EDIT
@import "functions/strip-sass"; // e.g. strip unit in SASS
@import "mixins/fonts-custom"; // DO NOT EDIT

// Custom variables
// EDIT THESE VARIABLES
@import "variables/color"; // Set base color variables
@import "variables/options"; // Enable base rounded / shadows
@import "variables/spacing-grid";
@import "variables/border-shadow-modal"; // Set base border, box shadow, and modal variables
@import "variables/typography"; // Set custom fonts and base typography variables
@import "variables/buttons-forms"; // Set buttons / forms base padding and other variables
@import "variables/breadcrumbs";
@import "variables/transitions";
@import "variables/animations"; // Set general animation duration
@import "variables/navbar"; // Set navbar height, animation duration, and other variables

// Base styles
@import "base/bootstrap.scss"; // Bootstrap 4 base variables, mixins, and styles

// ==========================================================================
// Optional Plugins
// ==========================================================================

// Font Awesome 5.13.0
// @import "../plugins/fontawesome-pro/css/all.min.css";
// @import "../plugins/fontawesome-pro/scss/fontawesome.scss";

// Locomotive Scroll 4.1.0
@import "../plugins/locomotive-scroll/locomotive-scroll.scss";

// Anti Icons 1.0.0
@import "../plugins/anti-icons/variables";
@import "../plugins/anti-icons/light/style.css";
@import "../plugins/anti-icons/regular/style.css";
@import "../plugins/anti-icons/solid/style.css";
@import "../plugins/anti-icons/brands/style.css";

// // Icomoon
// @import "../plugins/icomoon/style.css";

// Slick Carousel
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

// ==========================================================================
// Antikode Custom Mixins, Utilities, Overriders, and Base
// ==========================================================================

// Custom Mixins
@import "mixins/gradients"; // Gradients mixns
@import "mixins/icons"; // Font awesome mixins
@import "mixins/prefix"; // Vendor-prefixed CSS helper
@import "mixins/position"; // Shorthandizes position declarations
@import "mixins/text-truncate"; // Text truncate multiline

// Plugin Style Overrider
@import "overrider/datatable";
@import "overrider/select2";

// Utilities
@import "utilities/background"; // e.g. .bg-overlay
@import "utilities/buttons"; // e.g. .btn-block-mw-100px, .btn-block-mw-200px, etc.
@import "utilities/grid"; // e.g. .row-0, .row-1, .row-10px, .row-20px, etc.
@import "utilities/position"; // e.g. .center-both, etc.
@import "utilities/sizing"; // e.g. .h-50px, .w-md-100-px, etc.
@import "utilities/spacing"; // e.g. .py-main, .mb-sm-down-1, etc.
@import "utilities/text"; // e.g. .text-truncate-twoline, etc.

// SCSS base component
@import "base/alert";
@import "base/animations";
@import "base/background";
@import "base/dropdown";
@import "base/forms";
@import "base/nav";
@import "base/post";
@import "base/reboot";
@import "base/table";

// ==========================================================================
// Anti Components
// ==========================================================================

@import "../../components/anti/accordion/accordion";
@import "../../components/anti/accordion/accordion-custom";
@import "../../components/anti/breadcrumb/breadcrumb";
@import "../../components/anti/breadcrumb/breadcrumb-custom";
@import "../../components/anti/buttons/buttons";
@import "../../components/anti/buttons/buttons-custom";
@import "../../components/anti/card/card";
@import "../../components/anti/card/card-custom";
@import "../../components/anti/slider/slider";
@import "../../components/anti/slider/slider-custom";
@import "../../components/anti/cover/cover";
@import "../../components/anti/cover/cover-custom";
@import "../../components/anti/cursor/cursor";
@import "../../components/anti/cursor/cursor-custom";
@import "../../components/anti/footer/footer";
@import "../../components/anti/footer/footer-custom";
@import "../../components/anti/forms/forms";
@import "../../components/anti/forms/forms-custom";
@import "../../components/anti/image/image";
@import "../../components/anti/image/image-custom";
@import "../../components/anti/link/link";
@import "../../components/anti/link/link-custom";
@import "../../components/anti/loader/loader";
@import "../../components/anti/loader/loader-custom";
@import "../../components/anti/modal/modal";
@import "../../components/anti/modal/modal-custom";
@import "../../components/anti/navbar/navbar";
@import "../../components/anti/navbar/navbar-custom";
@import "../../components/anti/newsletter/newsletter";
@import "../../components/anti/newsletter/newsletter-custom";
@import "../../components/anti/social/social";
@import "../../components/anti/social/social-custom";
@import "../../components/anti/utils/utils";
@import "../../components/anti/video/video";

// ==========================================================================
// Global Components
// ==========================================================================

@import "../../components/global/toast/toast";
@import "../../components/global/card-product/card-product";

// ==========================================================================
// Pages Styles
// ==========================================================================

// Main Pages
@import "pages/home";
@import "pages/about";
@import "pages/common";
@import "pages/auth";
@import "pages/privacy-policy";
@import "pages/terms-and-condition";
@import "pages/shop";
@import "pages/product-details";
@import "pages/contact-us";
@import "pages/faq";
@import "pages/account";
@import "pages/checkout";
@import "pages/search";
@import "pages/payment";
@import "pages/category";
@import "pages/journal";
@import "pages/dwp-xv";

// ========================================================================================================================================
// Custom Styles
// ========================================================================================================================================

@import "../../lib/web3/component/component";

// ==========================================================================
// General
// ==========================================================================

// Insert custom style here

.image-reverse {
  transform: scaleX(-1);
}

.loading-anim {
  animation: spin 1.2s infinite linear;
}

// Cursor for custom cursor slider
.cursor {
  cursor: pointer;
  height: 4rem;
  width: 4rem;
  position: absolute;
  z-index: 99999;
  top: 0;
  border-radius: 100%;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

// ==========================================================================
// NProgress
// ==========================================================================
/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: $orange;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px $orange, 0 0 5px $orange;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

// ==========================================================================
// not found page
// ==========================================================================

.sc-404-notFound {
  .cover-bg {
    margin-left: 35%;
    background-position: right !important;

    @include media-breakpoint-down(lg) {
      margin-left: 0;
      background-position: center !important;
      // background-size: auto !important;
    }
  }

  .cover-body {
    @include media-breakpoint-up(lg) {
      @include gradient-x-to-right($black, transparent, 35%, 65%);
    }

    .container {
      @include media-breakpoint-down(lg) {
        text-align: center;
      }
    }
  }

  .img-notif {
    position: absolute;
    top: 0;
    right: 0;
    width: 220px;
  }
}

.pointer {
  cursor: pointer;
}

.sc-size-guide {
  .size-guide-table {
    width: 100%;

    td,
    th {
      border: 1px solid $gray-300;
      padding: 1rem;
      text-align: center;
    }

    th {
      background-color: $gray-200;
    }
  }
}

.sort-filter-mobile {
  @include media-breakpoint-up(md) {
    display: none;
  }

  margin-left: -$container-padding;
  margin-right: -$container-padding;
  border-bottom: 1px solid $gray-100;
  display: flex;

  .sort-filter-mobile-item {
    position: relative;
    width: 50%;
    cursor: pointer;
    border-right: 1px solid $gray-100;
    padding: 0.75rem $container-padding;
    top: 50%;

    &:last-child {
      border-right: none;
    }
    i {
      margin-right: 0.5rem;
      position: relative;
      top: 0.1rem;
    }
    select {
      background-color: transparent;
      appearance: none;
      border: none;
      color: transparent;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      &:focus {
        box-shadow: none;
      }
    }
  }
}

.error-page {
  width: 100vw;
  // height: 100vh;
  display: flex;
  justify-content: center;
  background-color: $white;
  .wrapper {
    height: fit-content;
    max-width: 400px;
  }
}

.loading-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 99999;
  background: white;
  inset: 0;
  opacity: 1;
  transition: all 1s ease-in-out;
  .img-lottie {
    height: 150px;
    width: 150px;
    @include media-breakpoint-up(md) {
      height: 175px;
      width: 175px;
    }
  }
  &.hide {
    opacity: 0;
    visibility: hidden;
    z-index: -9999;
  }
}

.not-found {
  min-height: 600px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .not-found-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .img-not-found {
    width: 250px;
    @include media-breakpoint-up(md) {
      width: 350px;
    }
  }
  .text {
    font-size: 1.125rem;
    text-align: center;
  }
}

.captcha {
  @include media-breakpoint-down(md) {
    transform: scale(1.15);
    transform-origin: 0 0;
    //     transform: "scale(1.15)",
    // transformOrigin: "0 1",
  }
}

.google-login-box {
  @include media-breakpoint-down(md) {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 1rem 0 !important;
    border: 1px solid $dark !important;
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
}
