// Color system
// Version 2.0.2

/* Normal Gray */
$white:    #ffffff !default; // Antikode
$gray-10:  #fcfcfc !default; // Antikode
$gray-20:  #fafafa !default; // Antikode
$gray-50:  #f2f2f2 !default; // Antikode
$gray-70:  #f9f9f9 !default; // Antikode
$gray-100: #e5e5e5 !default; // Antikode
$gray-150: #e0e0e0 !default; // Antikode
$gray-200: #cccccc !default; // Antikode
$gray-300: #BDBDBD !default; // Monstore
$gray-400: #999999 !default; // Antikode
$gray-500: #808080 !default; // Antikode
$gray-600: #666666 !default; // Antikode
$gray-700: #4d4d4d !default; // Antikode
$gray-800: #333333 !default; // Antikode - $body-color
$gray-900: #191919 !default; // Antikode
$black:    #000000 !default;

/* Slate Gray */
// $white:    #fff;
// $gray-10:  #fcfcfc !default; // Antikode
// $gray-20:  #fafafa !default; // Antikode
// $gray-50:  #f1f3f4 !default; // Antikode
// $gray-70:  #e2e6e9 !default; // Antikode
// $gray-100: #e9ecee !default; // Antikode
// $gray-200: #c8ced5 !default; // Antikode
// $gray-300: #b2bbc3 !default; // Antikode
// $gray-400: #9ca7b2 !default; // Antikode
// $gray-500: #7b8a99 !default; // Antikode
// $gray-600: #677685 !default; // Antikode
// $gray-700: #4e5964 !default; // Antikode
// $gray-800: #343b43 !default; // Antikode - $body-color
// $gray-900: #1a1e22 !default; // Antikode
// $black:    #000001 !default;

$grays: (
  "10": $gray-10, // Antikode
  "20": $gray-20, // Antikode
  "50": $gray-50, // Antikode
  "70": $gray-70, // Antikode,
);
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900,
  ),
  $grays
);

// Main Colors
$blue:       #007bff !default;
$indigo:     #6610f2 !default;
$blue-dark:  #113259 !default;
$purple:     #6f42c1 !default;
$pink:       #e83e8c !default;
$red:        #d84351 !default; // Monstore
$orange:     #FF4C07 !default; // Monstore
$yellow:     #EFCB70 !default; // Monstore
$green:      #47B297 !default; // Monstore
$teal:       #20c997 !default;
$cyan:       #17a2b8 !default;
$golden:     #cba67d !default; // Antikode

// Status Colors
$status-pending-bg:      #EFCB70;
$status-pending-text:    #523E0B;
$status-paid-bg:         #B4D7F1;
$status-paid-text:       #1A463B;
$status-delivery-bg:     #5DB2EF;
$status-delivery-text:    $gray-800;
$status-completed-bg:    #87C7B6;
$status-completed-text:  #1A463B;
$status-cancelled-bg:     $gray-300;
$status-cancelled-text:   $gray-800;
$status-returned-bg:     #F58A60;
$status-returned-text:   #5A1A01;

$status-text-colors: (
  "pending-text":     $status-pending-text,
  "paid-text":        $status-paid-text,
  "delivery-text":    $status-delivery-text,
  "completed-text":   $status-completed-text,
  "cancelled-text":   $status-cancelled-text,
  "returned-text":    $status-returned-text,
);
$status-bg-colors: (
  "pending-bg":       $status-pending-bg,
  "paid-bg":          $status-paid-bg,
  "delivery-bg":      $status-delivery-bg,
  "completed-bg":     $status-completed-bg,
  "cancelled-bg":     $status-cancelled-bg,
  "returned-bg":      $status-returned-bg,
);

// Brand Colors
$purple-instagram:#dd2a7b !default; // Antikode
$blue-twitter:    #00acee !default; // Antikode
$blue-facebook:   #1877f2 !default; // Antikode
$blue-linkedin:   #0077b5 !default; // Antikode
$blue-opensea:    #2081e2 !default; // Antikode
$red-youtube:     #e62117 !default; // Antikode
$red-pinterest:   #E60023 !default; // Antikode
$green-whatsapp:  #25D366 !default; // Antikode
$green-medium:    #00ab6c !default; // Antikode
$pink-tiktok:     #ee1d52 !default; // Antikode
$purple-discord:  #7289da !default; // Antikode
$red-email:       #e54e43 !default; // Antikode

$brand-colors: (
  "instagram":  $purple-instagram, 
  "twitter":    $blue-twitter,
  "facebook":   $blue-facebook, 
  "linkedin":   $blue-linkedin, 
  "opensea":    $blue-opensea, 
  "youtube":    $red-youtube,
  "pinterest":  $red-pinterest,
  "whatsapp":   $green-whatsapp,
  "medium":     $green-medium,
  "tiktok":     $pink-tiktok,
  "discord":    $purple-discord,
  "email":      $red-email,
);

$colors: (
  "golden": $golden, // Antikode,
);
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "blue":      $blue,
    "indigo":    $indigo,
    "purple":    $purple,
    "pink":      $pink,
    "red":       $red,
    "orange":    $orange,
    "yellow":    $yellow,
    "green":     $green,
    "teal":      $teal,
    "cyan":      $cyan,
    "white":     $white,
    "gray":      $gray-600,
    "gray-dark": $gray-800,
  ),
  $colors
);

$primary:     $gray-800; // Antikode
$secondary:   $gray-500; // Antikode
$success:     $green;
$info:        $cyan;
$warning:     $orange;
$danger:      $red;
$light:       $gray-20; // Antikode
$dark:        $gray-800; // Antikode

$theme-colors: (
  "gray-10":  $gray-10, // Antikode
  "gray-20":  $gray-20, // Antikode
  "gray-50":  $gray-50, // Antikode
  "gray-70":  $gray-70, // Antikode
  "gray-100": $gray-100,
  "gray-200": $gray-200,
  "gray-300": $gray-300,
  "gray-400": $gray-400,
  "gray-500": $gray-500,
  "gray-600": $gray-600,
  "gray-700": $gray-700,
  "gray-800": $gray-800,
  "gray-900": $gray-900,
  "black":    $black,
  "white":    $white,
);
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":   $primary,
    "secondary": $secondary,
    "success":   $success,
    "info":      $info,
    "warning":   $warning,
    "danger":    $danger,
    "light":     $light,
    "dark":      $dark,
  ),
  $theme-colors
);

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8%;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 150;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-900;
$yiq-text-light: $white;

// Characters which are escaped by the escape-svg function
$escaped-characters: (("<", "%3c"), (">", "%3e"), ("#", "%23"));

// Body Color
//
// Settings for the `<body>` element.

$body-bg: $white;
$body-color: $black; // Antikode
$body-color-secondary: $gray-400; // Antikode

// Links Color
//
// Style anchor elements.

$link-color: theme-color("primary");
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none; // Antikode
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15%;

$btn-hover-bg-darken: 5%; // Antikode (default: 7.5%)
$btn-hover-border-darken: $btn-hover-bg-darken; // Antikode (default: 10%)
// $btn-hover-active-bg-darken: 10%; // Antikode
// $btn-hover-active-border-darken: $btn-hover-active-bg-darken; // Antikode (default: 12.5%)