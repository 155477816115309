.alert-ribbon {
  width: 100%;
  background-color: $blue-dark;
  color: $white;
  font-size: 0.875rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 0;
  @include media-breakpoint-down(sm) {
    padding-left: 0;
    padding-right: 0;
  }
  p {
    @include media-breakpoint-down(sm) {
      max-width: 90%;
    }
  }
  a {
    font-weight: $font-weight-bold;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }
  .btn {
    width: 2rem;
    height: 2rem;
    padding: 0;
    line-height: 2rem;
    text-align: center;
    color: $white;
  }
}

// Product Item
.product-item {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $gray-100;
  padding: 0.5rem 0;
  &:last-child {
    border-bottom: 0;
  }
  .col-image {
    width: 150px;
    margin-right: 1.5rem;
  }
  .col-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 2rem;
    .name {
      color: $body-color;
      font-weight: $font-weight-bold;
      line-height: $headings-line-height;
      margin-bottom: 0.5rem;
      @include media-breakpoint-up(md) {
        font-size: 1.125rem;
      }
    }
    .option-1,
    .option-2 {
      margin-bottom: 0;
      font-family: $font-family-serif;
      font-size: 0.75rem;
    }
    .att {
      max-width: 200px;
      margin-bottom: 0.5rem;
      display: flex;
      .att-item {
        margin-bottom: 0;
        font-size: 0.9rem;
        line-height: 1;
        display: flex;
        align-items: center;
        &.size {
          min-width: 75px;
        }
        &.qty {
          border-left: 1px solid $gray-200;
          padding-left: 0.75rem;
        }
        &.qty-adjust {
          display: inline-flex;
          .form-control {
            width: 2.5rem;
            height: 1.5rem;
            text-align: center;
            font-size: 0.75rem;
            margin-left: -1px;
            margin-right: -1px;
            border-left-color: transparent;
            border-right-color: transparent;
            padding: 0;
            &:focus {
              box-shadow: 0 0 0 1px $primary;
            }
          }
          .btn {
            height: 1.5rem;
            width: 1.5rem;
            padding: 0;
            text-align: center;
            border-radius: 0;
            &.btn-minus {
              @include ic-middle(
                $font-family: "Anti Icons Light",
                $icon: $ai-minus
              );
            }
            &.btn-plus {
              @include ic-middle(
                $font-family: "Anti Icons Light",
                $icon: $ai-plus
              );
            }
            &:after {
              font-size: 0.75rem !important;
            }
            &:disabled {
              background: $gray-100;
            }
          }
        }
      }
    }
    .price {
      color: $primary;
      font-weight: $font-weight-bold;
      font-size: 0.875rem;
      margin-bottom: 0;
    }
    .btn-remove {
      cursor: pointer;
      top: 0.25rem;
      right: 0;
      position: absolute;
      padding: 0.5rem;
      &:hover,
      &:focus,
      &:active {
        color: $red;
      }
    }
  }
}

.common-loader {
  display: flex;
  justify-content: center;
  font-size: 3rem;
  padding: 1.5rem 0;
  i {
    animation: spin 2s infinite linear;
  }
}
