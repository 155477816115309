.card-product {
  .card-body {
    position: relative;
    text-align: center;
    .product-title {
      margin-bottom: 0.5rem;
    }
    .product-price {
      margin-bottom: 0;
      &.is-on-sale {
        span {
          text-decoration: line-through;
          color: $secondary;
          @include media-breakpoint-down(sm) {
            display: block;
          }
          &.sale-price {
            color: $danger;
            text-decoration: none;
            font-size: 0.875rem;
            font-weight: $font-weight-bold;
            @include media-breakpoint-up(md) {
              margin-left: 0.25rem;
            }
          }
        }
      }
    }
    .holders-only-logo {
      position: absolute;
      top: -100%;
      right: 1rem;
      width: 20%;
      @include media-breakpoint-up(sm) {
        top: -45%;
      }
      @include media-breakpoint-up(lg) {
        top: -100%;
      }
    }
  }
}
