// ========================================================================================================================================
// Breadcrumb - Custom
// ========================================================================================================================================

// Add custom style here

.breadcrumb {
  .breadcrumb-journal {
    .breadcrumb-link {
      color: $white;
    }
  }
  .breadcrumb-item {
    text-transform: uppercase;
    color: rgba($dark, 0.5) !important;
    &:hover {
      cursor: pointer;
      color: rgba($dark, 1) !important;
    }
    &:last-child {
      color: rgba($dark, 1) !important;
    }
    .breadcrumb-link {
      padding-bottom: 0.25rem;
      @include hover-underline;
    }
  }
}


