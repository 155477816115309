.bd-journal {
  .navbar {
    .navbar-main {
      background-color: $dark !important;
    }
  }
  .sc-journal {
    .journal-header {
      background-color: $gray-800;
      .journal-header-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 30vh;
        .title-wrapper {
          border-color: $white;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .tags {
            color: $white;
            font-size: 1.125rem;
            font-weight: $font-weight-bold;
            font-family: $headings-font-family;
            margin-bottom: 0;
          }
          .title {
            color: $white;
            font-size: 2.2rem;
            font-weight: $font-weight-semibold;
            margin: 0;
            padding: 0;
          }
        }
        .detail-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: absolute;
          width: 100%;
          p {
            margin-bottom: 0;
          }
          .date {
            color: rgba($gray-50, 0.6);
          }
          .share-btn {
            cursor: pointer;
            color: $white;
            font-family: $headings-font-family;
            font-weight: $font-weight-bold;
          }
          @include media-breakpoint-down(sm) {
            bottom: 1rem;
          }
        }
        .breadcrumb-wrapper {
          position: absolute;
          left: 0;
          top: 0;
          .breadcrumb {
            .breadcrumb-item {
              &:last-child {
                color: $white !important;
              }
            }
          }
          // .breadcrumb {
          //   .breadcrumb-item {
          //     &:first-child {
          //       a {
          //         color: $white !important;
          //         font-weight: $font-weight-bold;
          //       }
          //     }
          //     a {
          //       color: $white !important;
          //       font-weight: $font-weight-light;
          //       &::before {
          //         background: $white;
          //       }
          //     }
          //     color: $white !important;
          //     font-weight: $font-weight-light;
          //     &::before {
          //       color: $white !important;
          //     }
          //   }
          // }
        }
        .img-label {
          position: absolute;
          height: 85%;
          object-fit: contain;
        }
      }
    }
    .journal-list {
      padding-top: 30px;
      .card-journal {
        .card-body {
          .card-title {
            font-size: 1rem;
            line-height: 1.25rem;
            margin-bottom: 0.75rem;
          }
          .card-text {
            @include text-truncate-multiline(3);
            font-family: $headings-font-family;
            font-size: 0.75rem;
            line-height: 15px;
            color: $gray-800;
            margin-bottom: 0.5rem;
          }
          .date {
            color: $secondary;
            font-size: 0.875rem;
            font-weight: 300;
            line-height: 18px;
          }
        }
      }
    }
  }
  .sc-journal-cover {
    .cover {
      margin-top: 0;
    }
  }
  .sc-other-journal {
    .journal-list {
      .card-journal {
        .card-body {
          .card-title {
            font-size: 1rem;
            line-height: 1.25rem;
            margin-bottom: 0.75rem;
          }
          .card-text {
            @include text-truncate-multiline(3);
            font-family: $headings-font-family;
            font-size: 0.75rem;
            line-height: 15px;
            color: $gray-800;
            margin-bottom: 0.5rem;
          }
          .date {
            color: $secondary;
            font-size: 0.875rem;
            font-weight: 300;
            line-height: 18px;
          }
        }
      }
    }
  }
}
