.sc-faq-main {
  .title {
    font-weight: 400;
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  .search-field-wrapper {
    position: relative;
    .search-field {
      // width: 100%;
      .form-control-wrapper {
        &.ic-left {
          &:before {
            font-family: "Anti Icons Regular";
            content: varIcon($ai-search);
            color: $primary;
          }
        }
      }
    }
    > i {
      right: 0;
      bottom: 0.5rem;
      position: absolute;
      font-size: 1.5rem;
      color: $primary;
      font-weight: 400;
      &.ai-times {
        cursor: pointer;
      }
    }
  }
  .btn-contact-us {
    border-radius: 2rem;
    width: 100% !important;
  }
  .faq-content {
    a {
      color: $primary;
      font-weight: bold;
      padding-bottom: 0.3rem;
      @include hover-underline;
    }
  }
}
