.sc-payment {
  position: relative;
  .payment-info {
  }

  @include media-breakpoint-up(md) {
    .payment-info {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .colored {
    position: absolute;
    width: 100%;
    height: 75%;
    bottom: 0%;
    // background-color: #dadedc;
  }

  .content-wrapper {
    margin: auto;
    max-width: 650px;
    .btn.continue-btn {
      border: 1px solid $dark;

      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }

  .content {
    width: 100%;
    //  min-height: 30rem;
    position: relative;
    padding: 2.5rem;
    margin-bottom: 1rem;

    @include media-breakpoint-down(sm) {
      padding: 1.5rem 1rem;
    }

    &.waiting {
      border: 1px solid $gray-400;
      .order-number {
        // background: $fossil-gray;
        color: $white;
      }

      .method-wrapper {
        .text-body-4 {
          // color: $iron-gray;
        }
      }

      .text-body-3,
      .text-caption-1 {
        // color: rgba($gray-800, 0.7);
      }

      .payment-instruction {
        .box-payment {
          .box-header {
            // background-color: $pearl-river;
          }

          // border: 1px solid $pearl-river;
          border: 1px solid $dark;
        }

        .step {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
        }

        .v-stepper {
          position: relative;
        }

        .step .stepper-circle {
          border: 1px solid $gray-800;
          border-radius: 50%;
          width: 1.5rem;
          height: 1.5rem;
          text-align: center;
          background-color: $white;
        }

        .step .stepper-line {
          top: 1.5rem;
          left: 0.75rem;
          height: 100%;
          position: absolute;
          border-left: 1px dashed $gray-800;
        }

        .step:last-child .stepper-line {
          border-left: none;
        }

        .stepper-content {
          margin-left: 2rem;
          margin-bottom: 2rem;
          width: 100%;

          @include media-breakpoint-down(sm) {
            margin-left: 1rem;
            margin-bottom: 1.5rem;
          }
        }
      }
    }

    // &.order-details,
    // .order-details {
    //    .content-header {
    //       .text-title-2 {
    //          @include media-breakpoint-down(sm) {
    //             @include text-title-3;

    //             &.code-order {
    //                @include text-title-3-mobile;
    //             }
    //          }
    //       }

    //       .text-body-4 {
    //          color: rgba($gray-800, 0.7);

    //          &.date {
    //             color: $gray-800;
    //          }
    //       }
    //    }

    //    .items {
    //       .text-title-3 {
    //          @include media-breakpoint-down(sm) {
    //             @include text-title-4;
    //             margin-bottom: 0.5rem;
    //          }
    //       }

    //       .text-body-4 {
    //          // color: $iron-gray;
    //             color: $gray-800;
    //       }

    //       .text-body-3 {
    //          @include media-breakpoint-down(sm) {
    //             @include text-caption-2;
    //             color: $gray-800;

    //             &.price {
    //                @include text-body-3;
    //             }
    //          }
    //       }

    //       .summary-wrapper {
    //          .text-body-4 {
    //             color: rgba($gray-800, 0.7);
    //          }
    //       }
    //    }
    // }

    // &.shipping {
    //    .text-title-2 {
    //       @include media-breakpoint-down(sm) {
    //          @include text-title-3;

    //          &.code-order {
    //             @include text-title-3-mobile;
    //          }
    //       }
    //    }
    // }

    // &.success-content {
    //    @include media-breakpoint-down(sm) {
    //       padding: 2.5rem 0;
    //    }

    //    .header-content {
    //       text-align: center;
    //       padding-top: 3.75rem;
    //       padding-bottom: 1.75rem;
    //       color: $white;
    //    }

    //    .success-summary {
    //       background: $white;
    //       padding: 2.5rem;
    //       border: 1px solid $dark;

    //       @include media-breakpoint-down(sm) {
    //          padding: 2.5rem 1rem;
    //       }

    //       p {
    //          margin-bottom: 0.625rem;
    //       }

    //       .text-body-3 {
    //          color: rgba($gray-800, 0.7);

    //          &.text-right {
    //             color: $gray-800;
    //          }
    //       }

    //       .border-dashed {
    //          border-bottom: 1px dashed $dark;
    //          margin-top: 2.5rem;
    //          margin-bottom: 2.5rem;
    //       }
    //    }

    //    .circle {
    //       background-color: $white;
    //       border-radius: 50%;
    //       position: absolute;
    //       left: 50%;
    //       top: 2.5rem;
    //       transform: translate(-50%, -50%);
    //       height: 5rem;
    //       width: 5rem;

    //       .subcircle {
    //          height: 100%;
    //          width: 100%;
    //          border-radius: 50%;
    //          background-color: #dadedc;
    //          border: 1px solid black;

    //          i {
    //             color: $white;
    //             font-size: 2.4rem;
    //             font-weight: bold;
    //          }
    //       }
    //    }
    // }
  }
}

.payment-success {
  .col-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .col-img {
    display: none;
    @include media-breakpoint-up(lg) {
      align-items: center;
      justify-content: center;
      display: flex !important;
    }
    img {
      height: 100%;
      object-fit: contain;
      max-height: 600px;
    }
  }
  .brand-logo {
    width: 100px;
    margin-bottom: 1rem;
    img {
      width: 100%;
    }
  }
  .title {
    @include font-size(2.5rem);
  }
  .preorder {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    position: relative;
    background: $gray-50;
    padding: 1rem;
    display: flex;
    gap: 0.75rem;
    align-items: center;
    p {
      font-size: 0.9rem;
      color: $gray-800;
    }
    .img-package {
      width: 2rem;
      height: 2rem;
    }
  }
  .dwp {
    background: url("../../img/dwp/bg-dwp-xv-shop.jpg") center center no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem;
    img {
      max-width: 3.5rem;
      margin-right: 1rem;
    }
    p {
      color: $white;
      margin-bottom: 0;
    }
  }
}

.payment-waiting {
  padding: 1rem 1rem 2.5rem 1rem;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .header {
    padding: 2rem 1.25rem 1.25rem 1.25rem;
    border: 1px solid $dark;
    margin-bottom: 1rem;
    .title {
      text-align: center;
      margin-bottom: 1rem;
      text-transform: uppercase;
    }
    .info-wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 2rem;
      margin-bottom: 2rem;
      .info {
        display: flex;
        flex-direction: column;
        &:last-child {
          align-items: flex-end;
        }
        .label {
          color: $gray-500;
          font-size: 0.75rem;
        }
        .value {
          font-size: 1rem;
          color: $dark;
          font-weight: $font-weight-bold;
        }
      }
    }
  }
  .order-detail {
    background: $secondary;
    width: 100%;
    margin-bottom: 1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p,
    h4 {
      color: $white;
    }
    p {
      margin-bottom: 0.5rem;
      font-weight: $font-weight-light;
    }
  }
  .order-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    .btn {
      flex: 1;
    }
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      .btn {
        width: 100%;
      }
    }
  }
  p {
    margin: 0;
    padding: 0;
    @include media-breakpoint-down(md) {
      font-size: 0.7rem;
    }
  }
  .waiting-content {
    margin-bottom: 1.2rem;
    width: 100%;
    border: 1px solid $dark;
    padding: 1.7rem 1.3rem;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
    .title {
      text-transform: uppercase;
      text-align: center;
      border-bottom: 1px solid $gray-100;
      padding-bottom: 1rem;
      margin-bottom: 1.25rem;
    }
    .label {
      font-weight: 300;
    }
    .waiting-info {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $gray-100;
      p {
        font-size: 0.9rem;
        @include media-breakpoint-down(md) {
          font-size: 0.7rem;
        }
      }
      .label {
        font-size: 0.75rem;
        b {
          font-weight: $font-weight-bold;
        }
        &-value {
          font-weight: 300;
          span {
            color: $red;
            font-weight: $font-weight-bold;
          }
        }
      }
    }
    .waiting-detail {
      border-bottom: 1px solid $gray-100;
      .preorder-label {
        width: 75px;
        margin-bottom: 0.5rem;
      }
      p {
        padding: 0;
        margin: 0;
      }
    }
    .preorder {
      margin-top: 1rem;
      position: relative;
      background: $gray-50;
      margin-bottom: 1.125rem;
      padding: 1rem;
      display: flex;
      gap: 0.75rem;
      align-items: center;
      justify-content: center;
      p {
        font-size: 0.9rem;
        font-weight: 400;
        color: $gray-800;
      }
      .img-package {
        width: 2rem;
        height: 2rem;
      }
    }
    .waiting-payment-info {
      margin-top: 0.5rem;
      .info {
        padding-top: 0.5rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 0.5rem;
        .label {
          font-weight: 300;
          font-size: 0.875rem;
        }
        .total {
          font-weight: $font-weight-bold;
          font-size: 1.2rem;
          @include media-breakpoint-down(md) {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}
