.toast-wrapper {
  z-index: $zindex-top-3;
  position: fixed;
  right: 0;
  width: 100%;
  color: $white;
  padding: 1.25rem 2rem 1.25rem $container-padding;
  transition: $transition-base;
  font-size: $font-size-base;
  top: $navbar-main-height-mobile;
  @include media-breakpoint-up($navbar-expand-up) {
    top: $navbar-main-height-desktop;
    width: 500px;
  }
  &.success {
    background-color: $success;
  }
  &.danger {
    background-color: $danger;
  }
  &.warning {
    background-color: $warning;
  }
  &.verify {
    background-color: $blue-dark;
  }

  &.nav-toast {
    top: $navbar-height-mobile;
    @include media-breakpoint-up($navbar-expand-up) {
      top: $navbar-height-desktop;
    }
  }
  &.show {
    @extend .fadeInLeft
  }
  &.hide {
    visibility: hidden;
    @extend .fadeOutRight
  }
  .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem;
    color: $white;
    text-shadow: none;
    transition: $transition-base;
    cursor: pointer;
  }
  // .container {
  //   @include media-breakpoint-up($navbar-expand-up) {
  //     padding-left: 82px;
  //     padding-right: 145px;
  //   }
  //   .close-toast {
  //     position: absolute;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     height: 100%;
  //     top: -1.5rem;
  //     right: 0.5rem;
  //     .ai-times {
  //       font-size: 2rem;
  //     }
  //     @include media-breakpoint-up($navbar-expand-up) {
  //       right: 72px;
  //       top: 0;
  //     }
  //   }
  // }
}
