.sc-category-page {
  .category-menu {
    position: relative;
    background: $white;
    z-index: 2;
    .menu-wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      z-index: 2;
      background: $white;
      @include media-breakpoint-up($navbar-expand-up) {
        flex-direction: row;
      }
    }
    .title {
      @include media-breakpoint-up($navbar-expand-up) {
        position: absolute;
        left: 0;
      }
    }
    ul {
      padding: 0;
      display: flex;
      overflow-x: auto;
      margin-left: -1.25rem;
      margin-right: -1.25rem;
      margin-bottom: 0;
      &::-webkit-scrollbar {
        height: 0;
        width: 0;
        display: none;
      }
      &::-webkit-scrollbar-thumb {
        display: none;
      }
      @include media-breakpoint-up($navbar-expand-up) {
        overflow-x: unset;
        margin-left: unset;
        margin-right: unset;
      }
      li {
        padding: 0 1rem;
        text-transform: uppercase;
        list-style: none;
        font-weight: $font-weight-bold;
        font-family: $headings-font-family;
      }
    }
  }
  .brands-menu {
    transition: all 0.5s ease-in-out;
    position: absolute;
    width: 100%;
    background: $white;
    padding: 2rem 0 2rem 0;
    &.hide {
      z-index: 0;
    }
    &.show {
      z-index: 1;
      box-shadow: 0 4px 8px rgba($black, 0.15);
    }
    .brands-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding-bottom: 1rem;
      img {
        max-width: 35px;
        margin-right: 1rem;
      }
    }
  }
}

.sc-category-journal {
  position: relative;
  .slider {
    .slick-dots {
      li {
        button::before {
          content: none;
        }
      }
    }
    .wrapcover {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 2rem 0 0 0;
    }
    .container {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      height: 450px;
      img {
        width: fit-content;
        align-self: flex-end;
      }
    }
    .wrapper-dot {
      width: 100%;
      padding: 1.5rem;
      background-color: rgba($black, 0.4);
      .custom_dot {
        display: flex;
        align-items: center;
        justify-content: center;
        .dot {
          width: 0.5rem;
          height: 0.5rem;
          background: rgba(255, 255, 255, 0.5);
          border-radius: 100px;
          transition: 0.3s;
          cursor: pointer;
        }

        .active-dot {
          transition: 0.3s;
          background: $white;
        }
      }
    }
  }
  .slick-next,
  .slick-prev {
    display: none !important;
  }
}
