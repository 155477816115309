.sc-home-cover {
  @include media-breakpoint-up(md) {
    padding-top: 1.5rem;
  }
  // .slider {
  //   .slick-dots {
  //     li {
  //       button::before {
  //         content: none;
  //       }
  //     }
  //   }
  // }

  // position: relative;
  // .slick-next,
  // .slick-prev {
  //   display: none !important;
  // }
  // .wrapcover {
  //   background-position: center;
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   padding: 2rem 0 0 0;
  // }
  // .container {
  //   display: flex;
  //   justify-content: center;
  //   align-items: flex-end;
  //   height: 639px;

  //   img {
  //     // width: fit-content;
  //     // align-self: flex-end;
  //   }
  // }
  // .wrapper-dot {
  //   width: 100%;
  //   padding: 1.5rem;
  //   background-color: rgba($black, 0.4);
  //   .custom_dot {
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     .dot {
  //       width: 0.5rem;
  //       height: 0.5rem;
  //       background: rgba(255, 255, 255, 0.5);
  //       border-radius: 100px;
  //       transition: 0.3s;
  //       cursor: pointer;
  //     }

  //     .active-dot {
  //       transition: 0.3s;
  //       background: $white;
  //     }
  //   }
  // }
  .row-collections {
    .items-wrapper {
      position: relative;
      @include media-breakpoint-up(md) {
        margin-bottom: 1rem;
      }
      .overlay {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.4) 0%,
          rgba(0, 0, 0, 0) 37.5%
        );
        z-index: 1;
      }
      .buttons {
        position: absolute;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        bottom: 1.5rem;
        z-index: 2;

        .btn {
          width: 40%;
          padding-left: 1rem;
          padding-right: 1rem;
          &:first-child {
            margin-right: 0.5rem;
          }
          &:last-child {
            margin-left: 0.5rem;
          }
          @include media-breakpoint-down(sm) {
            width: 45%;
          }
          &.dwp-btn {
            width: 80%;
            @include media-breakpoint-down(sm) {
              width: 90%;
            }
          }
        }
      }
    }
  }
}

.sc-home-campaign {
  hr {
    background-color: $gray-100;
    margin: 0;
  }
}

.sc-home-featured {
  .title-wrapper {
    margin-bottom: 1rem;
    .title {
      margin-bottom: 0;
    }
  }
  .featured-list {
    .col-md-4.col-sm-6 {
      margin-bottom: 2.5rem;
    }
  }
}
