.sc-account-header {
  .container {
    padding-top: $navbar-height-desktop;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    @include media-breakpoint-up(md) {
      min-height: 182px;
      padding: 40px 40px 62px;
    }
    .title {
      font-size: 2.5rem;
      margin-bottom: 0.5rem;
    }
    .text {
      margin-bottom: 0;
    }
    .dwp-point {
      cursor: pointer;
      background: url("../../img/dwp/bg-dwp-xv-shop.jpg") center center
        no-repeat;
      background-size: cover;
      border-radius: 0.25rem;
      padding: 0.5rem 0.75rem;
      color: $white;
      display: flex;
      gap: 0.75rem;
      margin-top: 1.5rem;
      align-items: center;
      img {
        // max-width: 70px;
        height: 1.5rem;
        object-fit: contain;
      }
      i {
        cursor: pointer;
      }
    }
  }
}

.account-tab-wrapper {
  justify-content: center;
}

.nav-tabs-sm-down-justified {
  @include media-breakpoint-down(sm) {
    width: calc(100% + 2.5rem);

    > div {
      width: 100%;
    }

    .nav-tabs {
      width: 100%;

      .nav-item {
        flex-basis: 0 !important;
        flex-grow: 1 !important;
      }
    }
  }
}

.nav-tabs-wrapper {
  border-top: none;
  border-bottom: none;
  position: relative;
  display: flex;
  margin-bottom: 0;

  @include media-breakpoint-down(sm) {
    margin-left: -$container-padding;
    margin-right: -$container-padding;
  }

  &:before {
    position: absolute;
    content: "";
    background: $gray-100;
    bottom: 0;
    width: 100%;
    height: 1px;
  }

  .nav-scrollable {
    width: 100%;
    &:after {
      top: unset !important;
      bottom: 1px !important;
    }
  }
}

.nav-tabs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  .nav-item {
    flex-basis: unset;
    text-align: center;
    width: 100%;

    .nav-link {
      padding: 0.875rem 0;
      @extend .hover-underline;
      color: rgba($body-color, 0.75);
      text-transform: uppercase;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      &:before {
        height: 4px;
        bottom: 0;
      }
      &:after {
        display: none;
      }
      @include hover-focus-active {
        color: $body-color;
      }
      &.active {
        color: $body-color;
        font-family: $headings-font-family;
        font-weight: $font-weight-bold;
        &:before {
          transform: scale3d(1, 1, 1);
        }
      }
      @include media-breakpoint-down($navbar-expand-down) {
        font-size: 0.875rem;
      }
    }
  }
}

.sc-account-navbar {
  .account-nav {
    border-bottom: 1px solid $gray-100;
    .nav-link {
      @include hover-underline;
      @include media-breakpoint-down($navbar-expand-up) {
        font-size: 14px;
      }
      &::before {
        bottom: -1px;
      }
      &.active {
        font-weight: bold;
        &::before {
          bottom: -1px;
          content: "";
          display: block;
          position: absolute;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: $primary;
          z-index: 2;
          transform: scale3d(1, 1, 1);
        }
      }
    }
  }
}

.sc-account-myorder {
  .migrate-wrapper {
    .migrate-info {
      background: $gray-150;
      padding: 1rem;
      margin-bottom: 1.5rem;
      display: flex;
      justify-content: center;
      .content {
        display: flex;
        img {
          max-width: 125px;
          object-fit: contain;
        }
        div {
          &[role="lottie"] {
            max-width: 125px;
            object-fit: contain;
          }
        }
        .col-text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 1rem;
          h3,
          p {
            color: $dark;
            margin-bottom: 0;
          }
        }
        @include media-breakpoint-down(sm) {
          flex-direction: column;
          align-items: center;
          .col-text {
            margin-left: 0;
            margin-top: 1rem;
            text-align: center;
          }
        }
      }
    }
  }
  .table-order {
    margin-bottom: 5rem;
    .table-header {
      background-color: $gray-50;
      padding: 0.55rem 0;
      .table-header-item {
        font-size: 0.675;
        text-transform: uppercase;
        padding-left: 1rem;
        color: $gray-600;
      }
    }
    .table-body {
      border-bottom: 1px solid $gray-100;
      transition: $transition-base;
      @include hover {
        background-color: $gray-70;
        cursor: pointer;
      }
      .body-content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 1rem;
        &.alt {
          justify-content: unset;
        }
        &.amount {
          color: $primary;
        }
        .order-id {
          font-size: 1rem;
          margin-bottom: 0.25rem;
          font-weight: $font-weight-bold;
        }
        .name {
          margin-bottom: 0.25rem;
          font-size: 0.875rem;
        }
        .quantity {
          font-size: 0.75rem;
          color: $gray-600;
        }
        .order-img {
          min-width: 100px;
        }
        .status {
          margin-bottom: 0;
          padding: 0.5rem;
          font-size: 0.75rem;
          text-transform: uppercase;
          min-width: 150px;
          text-align: center;
          @each $status, $color in $status-text-colors {
            &.#{$status} {
              color: $color;
            }
          }
          @each $status, $color in $status-bg-colors {
            &.#{$status} {
              background-color: $color;
            }
          }
        }
      }
    }
  }

  .no-order {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // padding: 165px 3rem 194px;
    padding: 2rem 3rem;
    margin-top: 5rem;
    margin-bottom: 5rem;
    position: relative;
    .img-emblem {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 304.57px;
      height: auto;
    }
    .text-no-order {
      font-weight: $font-weight-light;
      font-size: 2rem;
      line-height: 41px;
      margin-bottom: 1.5rem;
      text-align: center;
    }
  }

  .order-mobile {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    width: calc(100% + 2.5rem);
    padding-left: 1rem;
    border-bottom: 1px solid $gray-100;
    .order-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .order-date {
        font-size: 0.875rem;
      }
      .status {
        margin-bottom: 0;
        padding: 0.25rem;
        align-items: center;
        font-size: 0.75rem;
        text-transform: uppercase;
        min-width: 150px;
        text-align: center;
        @each $status, $color in $status-text-colors {
          &.#{$status} {
            color: $color;
          }
        }
        @each $status, $color in $status-bg-colors {
          &.#{$status} {
            background-color: $color;
          }
        }
      }
    }
    .order-body {
      display: flex;
      .order-desc {
        p {
          color: $dark;
          margin-bottom: 0.5rem;
          font-size: 0.75rem;
        }
        .order-id {
          margin-bottom: 0.25rem;
          font-size: 0.875rem;
          font-weight: $font-weight-bold;
        }
        .order-item-qty {
          color: $gray-600;
        }
        .order-price {
          font-size: 0.875rem;
        }
      }
    }
  }
  .filter-order-wrapper {
    display: flex;
    position: relative;
    padding: 19px 1.25rem;
    justify-content: center;
    background: #ffffff;
    box-shadow: 0px -4px 8px rgba($black, 0.15);
    margin-top: 1rem;
    @include media-breakpoint-up($navbar-expand-up) {
      display: none;
    }
    .filter-icon {
      position: absolute;
      top: 50%;
      left: 1.25rem;
      transform: translateY(-50%);
    }
    .title-filter {
      font-size: 0.875rem;
      margin-bottom: 0;
    }
  }
  .pagination-wrapper {
    .btn-primary {
      border-radius: 0;
    }
  }
}

.sc-account-myorder-detail {
  .status {
    padding: 1.5rem 0;
    @each $status, $color in $status-text-colors {
      .#{$status} {
        color: $color;
      }
    }
    @each $status, $color in $status-bg-colors {
      &.#{$status} {
        background-color: $color;
      }
    }
    .status-text {
      &::before {
        font-family: "Anti Icons Light";
        content: varIcon($ai-angle-left);
        margin-right: 1rem;
      }
      @each $status, $color in $status-text-colors {
        &.#{$status} {
          color: $color;
        }
      }
    }
    button {
      @include media-breakpoint-down($navbar-expand-down) {
        background: $white;
      }
    }
  }

  .order-detail-wrapper {
    .col-detail {
      // @include media-breakpoint-up(md) {
      //   padding: 0 1rem;
      // }
      .order-id {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $gray-100;
        margin-bottom: 1.5rem;
        .invoice {
          text-align: right;
        }
        .label {
          color: $secondary;
          font-weight: $font-weight-light;
          font-family: $font-default-serif;
          margin-bottom: 0;
          font-size: 0.75rem;
        }
        .value {
          font-size: 1.25rem;
        }
        @include media-breakpoint-down(sm) {
          margin-bottom: 0.5rem;
          .value {
            font-weight: $font-weight-light;
            font-size: 1rem;
          }
        }
      }
      .order-item,
      .delivery-details {
        border: none !important;
        .accordion-header {
          border-bottom: 2px solid $black !important;
          text-transform: uppercase;
        }
        .accordion-content {
          padding-top: 0.5rem !important;
          .item-list {
            display: flex;
            align-items: center;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            border-bottom: 1px solid $gray-200;
            .variant {
              font-size: 0.75rem;
              font-weight: $font-weight-light;
              margin-bottom: 0;
            }
            .price {
              font-weight: $font-weight-light;
            }
          }
          .preorder-label {
            width: 75px;
            margin-bottom: 0.5rem;
          }
        }
      }
      .delivery-details {
        .accordion-content {
          display: block;
          .shipping-detail {
            margin-top: 1rem;
            padding: 1rem 1.25rem !important;
            border: 1px solid $dark;
            &.with-tracking-logs {
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
          }
        }
      }
    }
    .col-summary {
      border: 1px solid $dark;
      padding: 1rem;
      .special-event-product {
        flex-direction: column;
        gap: 0.5rem;
        background: url("../../img/dwp/bg-dwp-xv-shop.jpg") center center
          no-repeat;
        background-size: cover;
        padding: 1rem;
        .title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-bottom: 1rem;
          i {
            color: $white;
            cursor: pointer;
          }
          img {
            width: 115px;
            object-fit: contain;
          }
        }
        p {
          color: $white;
        }
      }
      hr {
        border-top: 1px solid $gray-100;
      }
      .value {
        font-family: $font-default-serif;
        font-weight: $font-weight-bold;
      }
      .value,
      .label {
        &.total {
          font-family: $font-default-serif;
          font-weight: $font-weight-bold;
          font-size: 1.25rem;
        }
      }
      .equivalent {
        @extend .container;
        display: flex;
        justify-content: flex-end;
        padding-right: 0;
        small {
          color: $gray-800;
          font-size: 75%;
        }
      }
    }
  }
}

.sc-account-address {
  padding-top: 1rem;
  padding-bottom: 4.375rem;
  @include media-breakpoint-up($navbar-expand-up) {
    padding-bottom: 1rem;
  }
  .address-wrapper {
    .primary-label {
      text-transform: none;
      font-size: 0.9rem;
    }
    position: relative;
    height: 200px;
    border-radius: 2px;
    border: 1px solid $gray-100;
    padding: 1.25rem 0;
    padding-right: 5rem;
    transition: $transition-base;

    @include hover {
      transition: $transition-base;
      background-color: $gray-70;
    }
    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .label {
        font-size: 1.25rem;
        line-height: 26px;
        margin-bottom: 0.25rem;
      }
      .address {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 0.5rem;
        @include text-truncate-multiline(4);
      }
      .bottom-address {
        display: flex;
        gap: 1rem;
        .btn-link {
          font-size: 0.875rem;
          padding: 0.5rem 0;
        }
      }
    }

    &.new-address {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px dashed $gray-100;
      cursor: pointer;
    }

    .status {
      &.disable {
        background-color: $gray-50;
        .primary-label {
          color: $gray-700;
        }
      }
      border-radius: 5px;
      background-color: rgba(#004f94, 0.18);
      color: #004989;
      padding: 0.35rem;
      text-transform: uppercase;
      position: absolute;
      top: 1.25rem;
      right: 1.25rem;
      font-size: 10px;
    }
  }
}

.sc-account-settings {
  padding-top: 1rem;
  padding-bottom: 1.875rem;
  .google-account-wrapper {
    display: flex;
    justify-content: space-between;
    .text {
      width: 70%;
    }
    @include media-breakpoint-down(md) {
      display: block;
      .text {
        width: 100%;
      }
    }
  }
  @include media-breakpoint-up($navbar-expand-up) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .not-verified {
    width: 100%;
    background-color: $red;
    padding: 0.75rem 1rem;
    @include media-breakpoint-down($navbar-expand-up) {
      margin-bottom: 0.5rem;
    }
  }
}

// ==========================================================================
// Tracking Logs
// ==========================================================================

.modal-tracking-logs {
  .modal-title {
    border-bottom: 2px solid $black;
    padding-bottom: 0.5rem;
  }
  .close {
    margin: 0;
    i {
      font-size: 1.75rem;
    }
    @include media-breakpoint-up($navbar-expand-up) {
      top: 2rem;
      right: 2rem;
    }
  }
  .receipt-number {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $gray-50;
    padding: 1rem;
    .label {
      font-size: 0.75rem;
      color: $secondary;
      margin-bottom: 0;
    }
    .value {
      font-weight: $font-weight-bold;
      margin-bottom: 0;
    }
    .label,
    .value {
      &:last-child {
        text-align: right;
      }
    }
  }
  .tracking-logs {
    padding-left: 0;
    margin: 1rem 0;
    .tracking-log-item {
      position: relative;
      list-style: none;
      margin-bottom: 1rem;
      display: flex;
      .timeline {
        width: 1rem;
        height: 1rem;
        background: $gray-200;
        border-radius: 50%;
        display: block;
        margin-top: 0.25rem;
        margin-right: 2rem;
        position: relative;
        &:before,
        &:after {
          content: "";
          height: 250%;
          width: 2px;
          background: $gray-200;
          position: absolute;
          margin-left: -1px;
          left: 50%;
        }
        &:before {
          bottom: 1rem;
        }
        &:after {
          top: 1rem;
        }
      }
      .content {
        width: 100%;
      }
      .content {
        span,
        small {
          display: block;
        }
        small {
          color: $gray-500;
        }
      }
      + .tracking-log-item {
        &:before {
          height: 150%;
        }
      }
      &:first-child {
        .timeline {
          background: $success;
          &:before {
            height: 0;
          }
        }
      }
      &:last-child {
        .timeline {
          &:after {
            height: 0;
          }
        }
      }
    }
  }
}
