// ========================================================================================================================================
// Card - Custom
// ========================================================================================================================================

// // Turn on styles below for default zoom in effect on card with link
// a.card {
//   @extend .hover-zoomIn;
// }

.card {
  border-color: $gray-100; // Default: $gray-100
  // prettier-ignore
  @include box-shadow($box-shadow); // Show box shadow if $enable-shadows is enabled
  .card-img-hover {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0;
    opacity: 0;
    transition: $transition-base;
  }
  &:hover {
    cursor: pointer;
    .card-img-hover {
      transition: $transition-base;
      opacity: 1;
    }
  }
  .card-img-logo-bottom-right {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    width: 25%;
  }
  .card-img-logo-top-right {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 75px;
  }
}
