// Breadcrumbs
// Version 2.0.0

$breadcrumb-font-size:              null;

$breadcrumb-padding-y:              .75rem;
$breadcrumb-padding-x:              1rem;
$breadcrumb-item-padding:           .5rem;

$breadcrumb-margin-bottom:          1rem;

$breadcrumb-bg:                     $gray-200;
$breadcrumb-divider-color:          $gray-600;
$breadcrumb-active-color:           $gray-600;
$breadcrumb-divider:                quote("/");

$breadcrumb-border-radius:          $border-radius;