// ========================================================================================================================================
// Modal
// ========================================================================================================================================

// Add custom styles here

.ReactModal__Html--open,
.ReactModal__Body--open {
  // overflow-y: hidden; /* prevents background page from scrolling when the modal is open */
  // @extend .no-scroll;
  .ReactModal__Content--after-open {
    overflow: auto;
    @include media-breakpoint-up(md) {
      height: inherit;
    }
  }
}

//General ************************************************************************************************** //

.modal-wrapper {
  box-shadow: $box-shadow;
  .close {
    margin-top: 0;
    margin-right: 0;
    top: 1rem;
    right: 0.875rem;
    @include media-breakpoint-up(md) {
      top: 1.875rem;
      right: 1.5rem;
    }
    i {
      font-size: 1.5rem;
    }
  }
  .modal-body {
    padding: 0;
    @include media-breakpoint-down(sm) {
      overflow: scroll;
    }
    .modal-title {
      @include font-size($h4-font-size);
      padding-bottom: 1rem;
      border-bottom: 1px solid $black;
      margin-bottom: 1rem;
    }
  }
}

.ReactModal__Overlay {
  background: rgba($black, 0.6) !important;
  @include media-breakpoint-down(sm) {
    &:has(.modal-bottom) {
      &.ReactModal__Overlay--before-close {
        animation-delay: 0.5s;
        transition-delay: 0.5s;
        .modal-wrapper {
          animation: slideOutDown 0.5s $ease-cubic-bezier;
        }
      }
    }
  }
  .ReactModal__Content {
    // Utilities classes for ReactModal__Content
    &.modal-sm {
      @include media-breakpoint-up(md) {
        max-width: 500px !important;

        h2 {
          @include font-size($font-size-base * 2);
        }
      }
    }

    &.modal-youtube {
      .close {
        top: -1.5rem;
        right: 0.5rem;
        color: $white;
      }
    }

    &.modal-address {
      .modal-wrapper {
        max-height: 100%;
        overflow: auto;
      }
    }

    &.modal-review {
      .modal-title {
        text-align: center;
        padding-right: 0;
      }

      .rating {
        justify-content: center;

        .rating-stars {
          font-size: 3rem;
        }
      }
    }

    &.xendit-modal-wrapper {
      @include media-breakpoint-up(md) {
        max-width: 950px !important;
        max-height: 60vh !important;
      }

      @include media-breakpoint-up(lg) {
        max-width: 1000px !important;
        max-height: 60vh !important;
      }

      @include media-breakpoint-up(xl) {
        max-width: 45% !important;
      }

      .modal-body {
        padding: 0;
      }

      .xendit-iframe {
        width: 100%;
        height: 55vh !important;
      }
    }

    &.modal-drawer {
      @include media-breakpoint-down(xs) {
        margin: 0;
        position: relative;

        .modal-wrapper {
          position: absolute;
          bottom: 0;
          animation: fadeInUp 1s ease(out-quart) both;
        }
      }
    }

    &.modal-overlay {
      max-width: 100%;
      margin: 0;
      height: 100vh;

      // background: $secondary !important;
      .modal-wrapper {
        max-width: 550px;
        margin: 0 auto;
      }
    }

    &.modal-undertones {
      .modal-wrapper {
        max-width: 650px;

        .undertones__item {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: initial;

          div {
            width: 250px;
          }

          img {
            width: 120px;

            @include media-breakpoint-down(md) {
              margin-right: 1rem;
              width: 96px;
            }
          }

          @include media-breakpoint-up(md) {
            flex-direction: column;
            text-align: center;
          }
        }
      }
    }
  }
}

//General ************************************************************************************************** //

//Custom Modal ********************************************************************************************* //

.filter-modal {
  margin: 0 auto !important;
  align-items: flex-end !important;
  justify-content: center !important;

  .modal-wrapper {
    animation: slideInUp 0.25s $ease-cubic-bezier;
    opacity: 1;

    .close {
      top: 2rem;
      // right: 1.75rem;
    }

    .title-filter {
      font-weight: normal;
      font-size: 1rem !important;
    }
  }
}

.modal-connect-wallet {
  .title {
    text-transform: uppercase;
    border-bottom: solid $dark 1px;
  }
}
.modal-disconnect-wallet {
  z-index: 10001;
}

.oneLastStep-modal {
  .modal-wrapper {
    height: 100vh;

    .modal-body {
      .modal-content {
        height: 100%;
        padding: 0;

        .image-wrapper {
          background-position: top;
          background-repeat: no-repeat;
          background-size: auto;
        }

        .content-gradient {
          @include gradient-y-to-top($black, rgba($black, 0));

          .content-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-direction: column;
            color: $white;

            h2 {
              font-size: 48px;
            }
          }
        }
      }
    }
  }
}

.multiple-coupon {
  .applied-wrapper {
    .list-applied-promo {
      max-height: 30vh;
      overflow-y: scroll;
    }

    .promo-item {
      border: 1px solid $gray-100;
      padding: 1.5rem 1rem;
      border-radius: 5px;
      position: relative;
      cursor: pointer;

      .promo-desc {
        font-size: 0.8rem;
      }

      .promo-badge {
        position: absolute;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 10% 100%);
        top: 0;
        right: 0;
        background-color: $gray-200;
        padding: 0.2rem 0.8rem;
        font-size: 0.8rem;
        text-transform: capitalize;
      }

      .trash-badge {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 0.4rem 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.address-modal {
  position: relative;
  .blank-state {
    display: flex;
    height: 200px;
    justify-content: center;
    align-items: center;
    .text {
      font-size: 1.5rem;
      font-style: italic;
      padding: 0;
      margin: 0;
      font-weight: $font-weight-semibold;
    }
  }
  @include media-breakpoint-down($navbar-expand-down) {
    margin: 0 auto !important;
    align-items: flex-start !important;
    justify-content: center !important;

    .modal-wrapper {
      position: absolute;
      bottom: 0;
      // max-height: 100vh !important;
      height: 100%;
      margin: 0 !important;
      overflow-y: auto !important;
      animation: slideInUp 0.25s $ease-cubic-bezier;
      opacity: 1;

      .close {
        top: 1rem;
        right: 0.75rem;
      }

      .title-filter {
        font-weight: normal;
        font-size: 1rem !important;
      }
    }
  }

  .modal-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 7.5rem !important;

    .absolute-btn {
      position: absolute;
      bottom: 0;
    }
  }
}

.edit-profile-modal {
  @include media-breakpoint-down(sm) {
    margin: 0 auto !important;
    align-items: flex-start !important;
    justify-content: center !important;

    .modal-wrapper {
      height: fit-content;
      animation: slideInUp 0.35s $ease-cubic-bezier;
      opacity: 1;
      position: fixed;
      bottom: -0.5rem;
      .close {
        // top: 2rem;
        // right: 1.75rem;
      }

      .title-filter {
        font-weight: normal;
        font-size: 1rem !important;
      }
    }
  }
}
.address-list-modal {
  .address-list {
    .address-item {
      border: 1px solid $gray-200;
      cursor: pointer;
      .address-detail {
        p {
          font-size: 0.8rem;
        }
      }
      &.active {
        border: 1px solid $black;
        box-shadow: 0 0 0 1px $black;
      }
      &:hover {
        border: 1px solid $black;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    margin: 0 auto !important;
    align-items: flex-start !important;
    justify-content: center !important;
    .modal-wrapper {
      height: fit-content;
      animation: slideInUp 0.35s $ease-cubic-bezier;
      opacity: 1;
      position: fixed;
      bottom: -0.5rem;
      margin: 0 !important;
    }
  }
}
.modal-sort-filter-mobile {
  @include media-breakpoint-down($navbar-expand-up) {
    margin: 0 auto !important;
    align-items: flex-start !important;
    justify-content: center !important;
    .modal-wrapper {
      height: fit-content;
      animation: slideInUp 0.35s $ease-cubic-bezier;
      opacity: 1;
      // position: fixed;
      overflow: auto;
      margin-top: 0;
      .modal-body {
        overflow: auto;
        height: 100vh;
        .modal-content {
          padding-bottom: 7.5rem;
          .sc-filter-categories {
            border-bottom: 1px solid $gray-300;
            margin-bottom: 1rem;
            padding-bottom: 1rem;
            label {
              @include font-size($font-size-base * 0.875);
              color: $gray-400;
            }
            .list-category {
              list-style: none;
              padding-left: 0;
              .item-category {
                font-size: 1rem;
                font-weight: $font-weight-normal;
                &.all {
                  margin-bottom: 0.5rem;
                }
                &.selected {
                  font-weight: $font-weight-bold;
                }
              }
              .list-sub-category {
                border-bottom: none !important;
                .accordion-content {
                  padding-bottom: 0 !important;
                  ul {
                    list-style: none;
                    padding-left: 1rem;
                    margin-bottom: 0;
                    .item-sub-category {
                      font-size: 1rem;
                      margin-bottom: 0.5rem;
                      &.selected {
                        font-weight: $font-weight-bold;
                      }
                    }
                  }
                }
              }
            }
          }
          .button-section {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 1rem;
            background: $white;
            border-top: 1px solid $dark;
          }
        }
      }
    }
  }
}

.modal-google-login-box {
  padding: 1rem;
  border: 1px solid $dark;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: $box-shadow-sm;
  cursor: pointer;
  @include media-breakpoint-down(md) {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 1rem 0 !important;
    border: 1px solid gray !important;
  }
}

//Custom Modal ********************************************************************************************* //
